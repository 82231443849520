.tabs {
  width: 282px;
  height: 36px;
  border-bottom: 1px solid rgba(163, 200, 255, 0.5);
  margin-bottom: 39px;
  float: left;
}

.tab {
  height: 36px;
  float: left;
  padding: 8px 16px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: black;
  cursor: pointer;
}

.tab.selected {
  background: rgba(163, 200, 255, 0.5);
  color: #3D5CFF;
}
