.ibutton button{
  background: #0029FF;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 34px;
  height: 48px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.ibutton button:disabled {
  background: #0029FF;
  opacity: 0.2;
}

.ibutton img{
  margin-top: 8px;
}

.ibutton.xsm img{
  margin-top: 6px;
}

.ibutton button:hover{
  background: #6B83FF;
}

.ibutton button:active{
  background: #6B83FF;
}

.ibutton button{

}

.ibutton.sm button{
  height: 48px;
}

.ibutton.m button{
  height: 36px;
  line-height: 24px;
}

.ibutton.m img.left-icon{
  margin-top: 4px;
}

.ibutton.xsm button{
  height: 32px;
}

.ibutton img {
  width: 16px;
  height: 16px;
}

.ibutton img.right-icon{
  margin-left: 8px;
}

.ibutton img.left-icon{
  margin-right: 8px;
}

.ibutton.icon-only img.right-icon{
  margin-left: 0;
}

.ibutton.icon-only img.left-icon{
  margin-right: 0;
}

.ibutton .btn-secondary{
  background: none;
  padding-left: 16px;
  padding-right: 16px;
}


.ibutton.sm .btn-secondary{
  padding-left: 14px;
  padding-right: 14px;
}

.ibutton.xsm .btn-secondary{
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
}


.ibutton.secondary button{
  border: 2px solid #0029FF;
  border-radius: 5px;
  color: #0029FF;
}

.ibutton.secondary button:disabled{
  background: #FFFFFF;
  color: #0029FF;
}

.ibutton.secondary button:hover{
  border: 2px solid #0029FF;
  background: #E5EAFF;
}


