/** origin shield button */

.origin-shield-button-left-icon {
    width: 14px;
    height: 14px;
    padding: 1.17px;
    margin-right: 10px;
  }

.origin-shield-button-right-icon {
    width: 16px;
    height: 16px;
    padding: 0px 4.47px 0px 4.5px;
    rotate: 270deg;
  }

.origin-shield-button-text {
  color: #01072C;

  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.origin-shield-button {
  width: 180px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

/** configure shield dialog */

.provider-switch .toggle {
  width: 44px;
  height: 24px;
  display: inline;
  margin-right: 15px;
}

.provider-switch {
  width: 225px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height: 64px;
  margin-right: 18px;
  padding-left: 16px;
  margin-bottom: 24px;
}

.provider-switch .provider-title {
  margin-left: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01072C;
}

/** no provider dialog */

.origin-shield-no-providers {
  height: 313px;
  border: 1px solid #0000004D;
  color: #01072C;

  /* || Typography */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.origin-shield-no-providers > .content {
  width: 488px;
  height: 180px;
  position: relative;
  top: 67px;
  left: 104px;
}

.content .msg {
  width: 488px;
  height: 20px;
  top: 20%;
  position: relative;
}

.content .image .image-decor {
  position: absolute;
  top: 13px;
  right: 128px;
  width: 60px;
  height: 60px;
}

#origin_shield_form .form-switch .form-check-input{
  margin-top: 0 !important;
}

.origin-shield-modal .modal-lg{
  width: 748px;
  min-width: 748px;
}
#origin_shield_form .provider-switch{
  margin-right: 11px;
}

.origin-s3{
  display: flex;
  gap: 10px;
}
.update-s3-credentials-link{
  color: blue;
  text-decoration: underline;
  font-family: Rubik, sans-serif;
    cursor: pointer;
}

/*###############Origin modal content */

.origin-modal .modal-content{
  width: 748px;
  padding: 8px 24px;
  border-radius: 10px;
}
.origin-modal h6{
  margin-bottom: 0;

}

.origin-modal .modal-header{
  height: 64px;
  padding: 20px 0;
}

.origin-modal .modal-body{
  padding: 0;
}

.origin-modal h6{
  font-family: 'Rajdhani', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.origin-modal .modal-footer{
  padding: 20px 0px;
  gap: 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.modal-footer .reveal-settings{
  color: blue;
  text-decoration: underline;
  font-weight: 400;
  font-size: 20px;
  font-family: Rubik, sans-serif;
  cursor: pointer;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  line-height: 37px;
}

.origin-divider{
  border-top: 1px solid rgba(176, 178, 190, 0.7);
  padding-top: 24px
}

.origin-space-between{
  margin-bottom: 36px;
}

.origin-lock-icon img{
  stroke: #0029FF;
  width: 16px;
  height: 20px;
  margin:auto; 
}

.origin-http{
  text-transform: uppercase;
}

#domain-form .idropdown .dropdown-toggle .selected-label img {
  margin-top: 4px;
}
