.audit-log-filter-type {
  width: 224px !important;
  float: left;
  margin-right: 23px;
  height:48px;
}

.audit-log-filter-type .multiselect-container .searchBox{
  width: 220px;
}

.audit-logs .log-expanded{
  width: 1140px;
  padding-left: 0;
  padding-right: 0;
}

.audit-logs .log-expanded .form-label{
  margin-top: 10px;
}

.audit-logs .date-range-picker {
  margin-top: 32px;
}

.audit-logs-filters{
  height: 88px;
}

.audit-logs-buttons{
  margin-bottom: 10px;
  float: right;
}

.audit-logs-buttons .ibutton:first-child{
  margin-right: 10px;
}
