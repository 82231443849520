.drill-down {
  color: #000000;
}

.legend-container {
  grid-template-columns: auto auto;
  display: grid;
  flex-wrap: wrap;
  float: right;
  /* width: 247px; */
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}

.chart-card {
  background: #FFFFFF;
  border: 3px solid #E5EDFF;
  border-radius: 5px;
}

.chart-card.no-data{
  height: 524px;
}

.chart-card .card-footer {
  background: #FFFFFF;
  border-top: 3px solid #E5EDFF;
  min-height: 107px;
}

.graph-footer-table {
  margin-bottom: 0;
}

.graph-period-control{
  float: right;
  margin-top: 29px;
}

.graph-period-control div{
  float: left;
  background: #E5EDFF;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 80px;
  height: 36px;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
}

.graph-period-control div.active {
  background: #0029FF;
  color: #FFF;
}

.graph-period-control .first{
  border-radius: 10px 0 0 10px;
}

.graph-period-control .last {
  border-radius: 0 10px 10px 0;
}

.chart-card.map {
  height: 668px;
}

.chart-card.map .rsm-svg {
  margin-top: -50px;
}
.chart-card.map ul.legend-container{
  width: fit-content;
  float: right;
  list-style-type: none;
}

.chart-card.map .legend-color {
  border-width: 4px;
  border-style: solid;
  border-radius: 7px;
  width: 22px;
  display: inline-block;
  height: 12px;
  margin-right: 10px;
}

.chart-card.map .legend-text{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  float: right;
}

.chart-card .map-tooltip{
  background: #FFFFFF;
  opacity: 1 !important;
  color: #000000;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.chart-card .map-tooltip.place-top::after{
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
  border-top-color: #FFFFFF;
  border-top-style: solid;
  border-top-width: 6px;
}

.chart-card .map-tooltip .tooltip-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.badge-comparison .winner{
  background: rgba(245, 158, 11, 0.2);
  border-radius: 8px;
  width: 157px;
  height: 96px;
  float: left;
  padding: 8px;
  margin-right: 20px;
}

.badge-comparison.vertical .winner{
  display: none;
}

.badge-comparison .winner .winner-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  color: #000000;
  width: 131px;

}

.badge-comparison .winner .win-percent{
  font-family: 'Rubik', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}

.provider-metric {
  width: 118px;
  height: 68px;

  border-radius: 8px;
  float: left;
  padding: 16px;
  margin-right: 20px;
  margin-top: 12px;
}

.provider-metric .title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0;

  color: #000000;

  opacity: 0.5;
}

.provider-metric .legend-color {
  width: 4px;
  height: 20px;

  border-radius: 50px;
  float: left;
  margin-top: 17px;
  margin-right: 5px;
}

.provider-metric .metric-percent {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 55px;
}

.graph-with-vertical-summary .graph-container{
  width: calc(100% - 142px);
  padding: 16px;
}

.vertical-summary {
  float: right;
  border-left: 3px solid #E5EDFF;
  padding-left: 19px;
  padding-right: 19px;
  height: 398px;
  width: 142px;
}

.card-body {
  //min-height: 412px;
}

.vertical-summary .provider-metric{
  margin-right: 0;
}

.graph-with-vertical-summary.card-body{
  padding: 0;
}

.badge-comparison.vertical{
  position: absolute;
  bottom: 19px;
}

.badge-comparison.vertical .provider-metric {
  width: 105px;
}

.chart-card .graph-loading{
  position: absolute;
  top: 25%;
  right: 40%;
  text-align: center;
}

.graph-with-vertical-summary .graph-loading {
  right: 50%;
}

.view-container.dashboard .form-error-message{
  margin-top: 24px;
}

.chart-subtitle{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.stats-actions {
  display: flex;
  flex-direction: row;
}

.stats-actions .ibutton {
  margin-left: auto;
  margin-top: 8px;
}

.graph-dropdown{
  position: absolute;
  top: 16px;
  left: 200px;
  z-index: 2000;
}

.graph-dropdown .dropdown-menu {
  min-width: 100px;
}

.chart-subtitle{
  min-height: 20px;
}

.provider-breakdown-checkbox {
  margin-top: 8px;
  margin-left: 16px;
}

.top-tables {
  display: grid;
  gap: 20px;
  grid-template-columns: 50% 50%;
}

.top-table {
  border: 3px solid #E5EDFF !important;
  width: calc(100% - 10px);
  height: 621px;
}


.top-table .data-table tbody tr {
  border-bottom: 1px solid #E5EDFF !important;
}

.top-table .data-table tbody tr td {
  padding-left: 18px !important;
  height: 56px;
}
