.view-container.welcome-page{
  width: 1339px;
}

.welcome-card{
  display: flex;
  width: 1339px;
  height: 498px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 19px 34px 0 rgba(177, 193, 216, 0.80);
  margin-bottom: 44px;
  margin-top: 70px;
}

.docs-and-support-card{
  display: flex;
  width: 1339px;
  height: 447px;
  padding: 32px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 10px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 19px 34px 0 rgba(177, 193, 216, 0.80);
}

.welcome-title{
  color: #215EDF;
  font-family: Rajdhani, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 83.333% */
}

.welcome-text{
  color: #000;
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  width: 970px;
  height: 120px;
  margin-top: 24px;
}

.welcome-top{
  display: flex;
  padding: 0 19px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  height: 208px;
  margin-top: 24px;
}

.steps-section{
  display: flex;
  height: 191px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.welcome-step{
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.20);
  width: 425px;
}

.welcome-step:last-child{
  border-right: none;
}

.welcome-step-top{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.welcome-step-index{
  color: rgba(0, 0, 0, 0.50);

  /* Headline/H6 */
  font-family: Rajdhani, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
}

.welcome-step-title{
  color: #215EDF;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 142.857% */
}


.docs-section{
  display: flex;
  width: 473px;
  height: 329px;
  padding-top: 24px;

  flex-direction: column;
  gap: 33px;
}

.support-section{
  width: 456px;
  height: 329px;
  margin-left: auto;
  padding-left: 72px;
  border-left: 1px solid rgba(0, 0, 0, 0.20);
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.docs-title img{
  margin-right: 8px;
}

.docs-title{
  color: #215EDF;
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}

.docs-text{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.docs-top{
  display: flex;
  flex-direction: column;
  gap: 8px
}

.docs-links{
  display: flex;
  flex-direction: column;
}

.docs-links a{
  color: #0267FF;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px; /* 185% */
  text-decoration-line: underline;
}

.docs-links a img{
  margin-left: 9px;
}

.welcome-text a img{
  margin-left: 9px;
}
