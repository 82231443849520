.new-certificate-btn{
  margin-left: 16px;
}


.new-certificate{
  padding: 0;
  width: 100%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  padding-bottom: 100px;
}

.new-certificate .step-container{
  height: fit-content;
  width: fit-content;
  position:relative;
}

.new-certificate h1{
  margin-bottom: 59px;
  text-align: center;
}

.new-certificate h1 img{
  margin-top: -12px;
  margin-right: 16px;
  cursor: pointer;
}

.new-certificate .domains-desc{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 36px;
}

.current-domain{
  float: left;
}

.add-domain-btn{
  position: relative;
  float: left;
  margin-left: -5px;
  width: 96px;
}

.add-domain-btn button{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.new-certificate .domains-container{
  overflow: hidden;
  max-height: 48px;
}

.new-certificate .accordion-button::after{
  display: none;
}

.new-certificate .added-domains .title{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
}

.new-certificate .added-domains{
  overflow: auto;
  position: absolute;
}

.new-certificate .added-domain {
  margin-bottom: 8px;
}

.new-certificate .added-domain .checkmark{
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #0066FF;
  float: left;
  margin-right: 8px;
}

.new-certificate .added-domain .checkmark img{
  margin-top: -4px;
  margin-left: -2px;
}

.new-certificate .added-domain {
  color:  #01072C;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.new-certificate .instructions{
  position: relative;
}

.new-certificate .instructions-section {
  width: 570px;
  background: #E3EEFF;
  border-radius: 10px;
  margin-top: 26px;
  margin-bottom: 24px;
  padding-top: 24px;
  padding-left: 37px;
  height: 260px;
}

.new-certificate .instructions-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
}

.new-certificate .step{
  margin-bottom: 16px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 504px;
}

.new-certificate .step-ellipse{
  float: left;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #0066FF;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  margin-right: 11px;
}

.new-certificate .form-text-field{
  background: #FFFFFF;
}

.new-certificate .wizard-buttons{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 88px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 24px;
  background: #FFFFFF;
  z-index: 3;
}

.new-certificate .wizard-buttons .ibutton:first-child{
  margin-left: 10px;
}

.new-certificate .steps-indicator{
  position: fixed;
  bottom: 36px;
  width: 58px;
  margin: auto;
  height: 16px;
  left: calc(100vw / 2 - 29px);
}

.new-certificate .steps-indicator div{
  background: #E5EDFF;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  float: left;
  margin-right: 5px;
}

.new-certificate .steps-indicator div.current{
  background: #01072C;
}

.new-certificate .steps-indicator div:last-child{
  margin-right: 0;
}


.new-certificate .alert{
  width: 574px;
}

.new-certificate .next-challenge-icon{
  position: absolute;
  right: -24px;
  top: 78px;
  cursor: pointer;
}

.instructions .domain{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 12px;
}

.challenges-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 595px;
}

.challenges-super-container{
  width: 504px;
  overflow-x: visible;
}

.challenges{
  display: flex;
  flex-direction: row;
  gap: 94px;
  width: fit-content;
}

.certificates .icard{
  width: 403px;
  height: 224px;
}

.certificates .icard .cert-title{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.certificates .icard .body{
  padding-left: 16px;
}

.certificates .icard .certificate-body{
  color: rgba(0, 0, 0, 0.50);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 380px;
}

.cn-and-managed-by{
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.cn-and-managed-by .cn{
  margin-left: auto;
  margin-right: 16px;
}

.certificates .service-menu{
  right: 0;
  top: 40px;
  height: 114px
}

.dns-challenges{
  color: #000;
  /* Subtitle/S1 */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}



.dns-challenges .instructions{
  position: relative;
}

.dns-challenges .instructions-section {
  background: #E3EEFF;
  border-radius: 10px;
  margin-top: 26px;
  margin-bottom: 24px;
  padding-top: 24px;
  padding-left: 37px;
  height: 260px;
  width: 500px;
}

.dns-challenges .instructions-section:last-child {
  margin-right: 94px;
}

.dns-challenges .instructions-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
}

.dns-challenges .step{
  margin-bottom: 16px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 504px;
}

.dns-challenges .step-ellipse{
  float: left;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #0066FF;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  margin-right: 11px;
}

.dns-challenges .modal-content{
  width: 620px
}

.dns-challenges .next-challenge-icon{
  position: absolute;
  right: -60px;
  top: 78px;
  cursor: pointer;
}

.dns-challenges .challenges-super-container{
  width: 530px;
  overflow-x: visible;
}

.dns-challenges .modal-header{
  display: none;
}

.dns-challenges .form-text-field{
  background: #FFFFFF;
}

.dns-challenges .challenges-container {
  width: 594px;
  overflow-x: auto;
}

.new-certificate .created-alert{
  width: 562px;
  height: 64px;
  border-radius: 5px;
  background: rgba(30, 201, 47, 0.10);
  padding: 12px;
  line-height: 40px;
  margin-bottom: 28px;
}

.new-certificate .created-alert img{
  float: left;
  margin-right: 8px;
  margin-top: 10px;
}

.cns {
  max-height: 100px;
  overflow: auto;
}