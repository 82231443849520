
.register-background {
  width: 100%;
  min-height: 900px;
  height: 100%;

  background: url("/assets/register/register_back.svg") no-repeat center center;
  background-size: cover;
}

.register-form-container {
  position: relative;
  left: 106px;
  top: 188px;
  width: 425px;
  height: 436px;
}
.register-form-container form .form-label{
  color: #FFFFFF;
}

.register-form-container .ibutton{
  margin-bottom: 16px;
}

.register-form-container .ibutton button{
  width: 425px;
}

.register-form-container input{
  color: #FFF;
}

.register-form-container h5{
  margin-bottom: 64px;
}

.login-link {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.login-link a{
  font-weight: 700;
  color: #FFF;
  margin-left: 3px;
}

.register-form-container .form-error-message{
  color: #FF3D3D;
  background-color: rgba(255, 66, 66, 0.3);
}

.thank-you-message{
  top: calc(50% - 104px);
  position: absolute;
  text-align: center;
}

.thank-you-message img{
  width: 64px;
  height: 64px;
}
