.icheckbox {
  width: fit-content;
  height: 48px;
}

.icheckbox .single-checkbox{
  padding: 3px;
  cursor: pointer;
}

.icheckbox .single-checkbox .inline-label{
  width: fit-content;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #01072C;
  white-space: nowrap;
  margin-left: 16px;
  float: left;
}

.icheckbox .on-off {
  cursor: pointer;
  width: 96px;
  height: 48px;
  background: #FFFFFF;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  padding: 2px;
}

.icheckbox .on {
  width: 40px;
  height: 40px;
  background-size: 16px 16px;
  background: #0029FF url("/assets/icons/checkbox_checkmark.svg") no-repeat 10px 12px;
  border-radius: 3px;
}

.icheckbox.round {
  height: 33px;
}

.icheckbox.round .on {
  border-radius: 35px;
  width: 24px;
  height: 24px;
  background: #0029FF url("/assets/icons/checkbox_checkmark.svg") no-repeat 1px 7px;
  background-size: 22px 11px;
}

.icheckbox.round .off {
  border-radius: 35px;
  width: 24px;
  height: 24px;
}

.icheckbox .off {
  width: 40px;
  height: 40px;
  border: 2px solid #B0B2BE;
  background-size: 16px 16px;
  background: none; 
  border-radius: 3px;
}

.checkbox-with-label {
  display: flex;
  flex-direction: row;
  gap: 8px
}

.checkbox-label {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 64px;
  width: 188px;
  color: black;
  margin-top: 8px;
}

.icheckbox .on-small{
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-position: center;
  background: #0029FF url("/assets/icons/checkbox_checkmark_24.svg") no-repeat 4px 7px;
  margin-right: 8px ;

}

.icheckbox .off-small {
  width: 24px;
  height: 24px;
  border: 2px solid #B0B2BE;
  background-size: 16px 16px;
  background: none;
  border-radius: 3px;
  margin-right: 8px ;
}

.small-checkbox{
  display: flex;
  flex-direction: column;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 64px;
  color: black;
  padding: 0;
  height: 52px;
}

.icheckbox .single-checkbox-small{
  display: flex;
}
.icheckbox .description{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }

