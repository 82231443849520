// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "~bootstrap/scss/bootstrap";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

@import "register";
@import "form";
@import "login";
@import "dialog";
@import "card";
@import "providers";
@import "dropdown";
@import "app_sidebar";
@import "table";
@import "button";
@import "dashboard";
@import "progress";
@import "cache_management";
@import "behaviors";
@import "traffic_management";
@import "checkbox";
@import "badge";
@import "tooltip";
@import "traffic_policies";
@import "toggle_button";
@import "pagination";
@import "compute";
@import "waf";
@import "switch";
@import "monitoring";
@import "geos_multiselect";
@import "charts";
@import "userManagement";
@import "tokenManagement";
@import "auditLog";
@import "date_range_picker";
@import "time_field";
@import "import_service";
@import "services";
@import "accounts_chooser";
@import "breadcrumps";
@import "certificates";
@import "origin_sets";
@import "tabs";
@import "analytics";
@import "welcome";
@import "alerts";
@import "billing";
@import "contact_support";
@import "geo_fencing";
@import "onboarding_tips";

.service-grid-header-row {
  line-height: 40px;
}

.form-label {
  font-weight: 600;
  font-size: 14px;
}

.form-check-label {
  font-size: 14px;
  font-weight: 400;
}

.card-header{
  font-weight: 600;
}

.service-provider-header-containter ul {
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.service-provider-header-containter li:nth-child(1) { margin-right: auto; }
.service-provider-header-containter li:nth-child(3) { margin-left: auto; }

/* for demo only */
.service-provider-header-containter ul { padding: 0; margin: 0; list-style: none; }
.service-provider-header-containter li { padding: 0px; }
.service-provider-header-containter p  { text-align: center; }
.card-header {
  padding-bottom: 4px;
  padding-text-outline: 4px;
}

.loading-spinner{
  display: table-cell;
  height: calc(100vh - 112px);
  vertical-align: middle;
  margin: auto;
}

.loading-spinner-container {
  width: 100%;
  text-align: center;
  display: table;
}

.dollar:before {
  content: '$';
}

:-webkit-autofill {
  /* Expose a hook for JavaScript when auto fill is shown. */
  /* JavaScript can capture 'animationstart' events */
  animation-name: onAutoFillStart;

  // Make the backgound color become yellow _really slowly_
  transition: background-color 50000s ease-in-out 0s;
}

:not(:-webkit-autofill) {
  /* Expose a hook for JS onAutoFillCancel */
  /* JavaScript can capture 'animationstart' events */
  animation-name: onAutoFillCancel;
}
