.signed-urls-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 34px;
}

.generate-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.create-signing-key-body{
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  position: relative;
}


.table-keys-buttons{
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.copy-public-key {
  position: absolute;
  top: 312px;
  right: 0;
}

.copy-encryption-key{
  position: absolute;
  top: 428px;
  right: 0;
}

.copy-private-key{
  position: absolute;
  top: 639px;
  right: 0;
}

.signing-key-copy-warning{
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  background: rgba(163, 200, 255, 0.30);
}

.signing-key-copy-warning-header{
  display: flex;
  padding: 5px 0;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 5px 5px 0px 0px;
  background: #0029FF;
}

.signing-key-copy-warning-title {
  color: #FFF;
  text-align: center;

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.signing-key-copy-warning-body{
  color: #01072C;
  text-align: center;

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 403.173px;
}

.view-signing-key{
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.create-signing-key-header{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}

.view-signing-key-description{
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.key-name-section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.key-name-title{
  color: #01072C;

  /* Subtitle/S2 */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.key-name{
  color:  #111827;

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.key-json-container{
  display: flex;
  height: 344px;
  padding: 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 5px;
  background: #E8F5FF;
  color: #000;
  font-family: "JetBrains Mono", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.key-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
}


.copy-json-key {
  position: absolute;
  top: 367px;
  right: 0;
}
