.invite-form{
  float: left;
  width: 702px;
}

.permissions-description{
  background: rgba(163, 200, 255, 0.5);
  width: 408px;
  float: right;
  height: calc(100vh - 112px);
  padding-top: 27px;
  padding-left: 35px;
  padding-right: 32px;
}

.permissions-description .role-title {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-top: 26px;
}

.permissions-description .permission-icon{
  margin-right: 10px;
}

.invite-form .edit-footer, .user-info .edit-footer, .sso .edit-footer{
  border-top: 1px solid rgba(163, 200, 255, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  bottom: 0;
  width: 702px;
}

.user-info .edit-footer, .sso .edit-footer, .waf-custom-rules-overview .edit-footer, .rate-limit-custom-rules-overview .edit-footer{
  width: 1152px;
}

.invite-form .edit-footer .ibutton:last-child, .user-info .edit-footer .ibutton:last-child{
  margin-right: 10px;
}
.basic-user-info-form{
  width: 405px;
}

.mfa .qr-section {
  width: 720px;
  background: #E3EEFF;
  border-radius: 10px;
  margin-top: 26px;
  margin-bottom: 24px;
  padding-top: 40px;
  padding-left: 37px;
  height: 334px;
}

.mfa .qr-section img{
  width: 247.76px;
  height: 247.76px;
  margin-right: 38px;
}

.mfa .instructions-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
}

.mfa .step{
  margin-bottom: 16px;
}

.mfa .step-ellipse{
  float: left;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #0066FF;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  margin-right: 11px;
}

.mfa-switch-section {
  background: #FFFCDE;
  border-radius: 10px;
  width: 564px;
  height: 157px;
  padding: 36px;
  margin-top: 24px;
}

.mfa-switch-section .form-switch{
  margin-right: 16px;
}

.mfa-switch-section .enable-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.idp-info {
  padding-top: 44px;
  padding-left: 32px;
  padding-bottom: 36px;
  background: rgba(163, 200, 255, 0.3);
  border-radius: 10px;
  width: 922.95px;
  height: 453px;
}

.idp-info .form-text-field{
  background: #FFFFFF;
  margin-bottom: 12px;
}

.idp-info .form-text-field:first-child{
  margin-top: 32px;
}

.sso .metadata-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-top: 40px;
}

.sso .metadata-drop{
  width: 293px;
  height: 238px;

  border: 2px solid #B0B2BE;
  border-radius: 5px;
  margin-top: 30px;
  text-align: center;
}

.sso .dropzone img {
  margin-top: 70px;
}
.sso {
  height: calc(100vh - 230px);
  overflow: scroll;
}

.sso .metadata-drop-desc{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #B2B2B2;
}

.sso .metadata-upload-label{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #0029FF;
}


.sso .dropzone{
  width: 293px;
  height: 238px;

  border: 2px solid #B0B2BE;
  border-radius: 5px;
  margin-top: 30px;
  text-align: center;
}



.service-restrict .service-choose{
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.restrict-type {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #000;
  margin-left: 10px;
  margin-bottom: 10px;
}

.restrict-type label {
  margin-left: 13px;
}

.restrict-type  .type-radio input{
  transform: scale(2);
}

.chosen-services {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 12px;
  min-height: 200px;
}

.chosen-service {
  display: flex;
  flex-direction: row;
  gap: 12px;

}

.chosen-service .service-title{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top: 26px;
  color: #000;
}

.chosen-service .ibutton {
  margin-top: 16px;
  margin-left: auto;
}
