.ipagination .pagination {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 54px;
}

.ipagination .page-link {
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #374151;
}

.ipagination .page-item.disabled .page-link {
  background: #EEF2FF;
  border-radius: 6px;
  color: #4B4EFC;
}
