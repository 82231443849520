.progress-background {
  background: #fff;
  border-radius: 10px;
  height: 22px;
  margin-bottom: 8px;
  display: flex;
  gap: 11px;
}

.progress-indicator {
  position: relative;
  height: 22px;
  text-align: center;
  float: left;
}
.progress-background.full {
  width: 100%;
}

.progress-doughnut {
  width: 77px;
  height: 77px;
  float: right;
  margin-right: 10px;
}

.progress-background.doughnut .progress-indicator{
  border-radius: 10px;
  border-width: 2px;
  width: 67px;
  height: 67px;
}

.progress-background.full .progress-indicator{
  border-radius: 10px;
}

.progress-indicator.first {
  border-radius: 10px 0 0 10px;
}

.progress-indicator.first {
  border-radius: 10px 0 0 10px;
}

.progress-indicator.last {
  border-radius: 0 10px 10px 0;
}

.progress-text {
  position: relative;
  color: #FFF;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}

.progress-doughnut-title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  top: -47px;
  left: 19px;
}

.progress-background .legend-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  width: 100px;
}
