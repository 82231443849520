.cdn_provider_form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: #000000;
  padding-bottom: 10px;
}

.new-provider-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.cdn_provider_form_container .idropdown .dropdown-toggle {
  width: 294px;
}

.cdn_provider_form_container .apply-button{
  margin-bottom: 18px;
  width: 97px;
}

.cdn_provider_form_container .form-label {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.add_provider {
  padding-left: 14px;
  margin-bottom: 12px;
}

.add_provider h3{
  margin-top: 64px;
  color: #000000;
}

.service-providers .action-row{
  height: auto;
}

.add-service-provider form{
  height: 108px;
}

.add-provider-error {
  white-space: nowrap;
  overflow: auto;
  height: 64px;
  background-color: #FFDDDD;
  border-radius: 5px;
  width: 100%;

  line-height: 64px;
  background-repeat: no-repeat;
  background-position: 24px 20px;
  background-size: 20px 20px;
  background-image: url('/assets/icons/exclamation.svg');
  padding-left: 52px;
  margin-bottom: 10px;
  color: #782C00;
}

.aws-creds-type .type-radio{
  width: 352px;
  height: 48px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 56px;
  text-align: left;
  padding-left: 15px;
  color: black;
}

.aws-creds-type  .type-radio input{
  margin-right: 15px;
  transform: scale(2);
}

.provider-dropdown-field .dropdown-item {
  line-height: 36px;
}

.cdn_provider_form_container .dropdown-item{
  line-height: 36px;
}

.provider-card-body{
  display: flex;
  flex-direction: column;
}

.account-providers .provider-card-body {
  flex-direction: row;
}

.account-providers .action-row {
  --cui-gutter-x: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-left: calc(var(--cui-gutter-x) * 0.5);
  padding-right: calc(var(--cui-gutter-x) * 0.5);
}

.account-providers .action-row h3 {
  margin-top: 28px;
}

.commitment-usage-chart{
  background: rgba(0, 133, 255, 0.10);
  margin-left: auto;
  max-width: 140px;
  margin-right: 24px;
}

.account-providers .service-menu{
  right: 0;
  top: 40px;
  height: 116px
}

.icard .body .usage-text{
  color: var(--black-white-bw-300, #555972);
  font-family: Rubik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  text-align: center;
  padding-top: 9px;
  padding-bottom: 9px;
}

.account-providers .chartjs-tooltip{
  display: none !important;
}

.akamai-add-provider .checkbox-label {
  width: 250px;
}

#edit-provider-account {
  display: flex;
  flex-direction: column;
}

#edit-provider-account .idropdown button {
  width: 100%;
}

#edit-provider-account .dropdown-item img {
  margin-right: 10px;
  margin-top: 5px;
}

#edit-provider-account .form-check-label {
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px; /* 142.857% */

  margin-left: 16px;
}

#edit-provider-account .form-check-input {
  width: 24px;
  height: 24px;
}

.edit-provider-account-wait {
  cursor: wait;
}

.edit-credentials-warning {
  color: #000;

  /* Subtitle/S1 */
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */

  align-self: stretch;
  margin-bottom: 20px;
}
