.toggle-button {
  height: 64px;
  margin-bottom: 24px;
}

.toggle-button .ibutton {
  float:left;
}

.toggle-button div:last-child .ibutton button{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.toggle-button div:first-child .ibutton button{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
