.multi-value-badges {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.multi-value-badge{
  background: #EDF0FF;
  border-radius: 4px;
  margin-right: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  padding: 8px;

  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #101010;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
  margin-top: 10px;
}

.multi-value-badge img{
  margin-right: 10px;
}
