.date-range-picker{
  height: 56px;
  background: #E5EDFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 80px;
  width: fit-content;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.date-range-picker-toast{
  position: absolute;
  z-index: 1000000;
  top: 64px;
  margin-right: 624px;
  width: 976px;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.date-range-picker .toast-header {
  height: 0;
  border: navajowhite;
  padding: 0;
}

.date-range-picker-toast .calendar-pointer {
  position: absolute;
  top: -11px;
  right: 100px;
}

.date-range-picker-toast .calendar-pointer.bottom-left{
  position: absolute;
  top: 99%;
  left: 100px;
  transform: rotate(180deg);
}

.date-range-picker-toast .rc-calendar-input-wrap{
  display: none;
}

.date-range-picker-toast .rc-calendar{
  border: none;
  box-shadow: none;;
}


.date-range-picker-toast .rc-calendar-range-part {
  padding-left: 41px;
  padding-right: 41px;
  width: 392px;
}

.date-range-picker-toast .rc-calendar-panel{
  border: none;
}

.date-range-picker-toast .rc-calendar-date-panel{
  border: none;
}

.date-range-picker-toast .rc-calendar-range-part{
  border: none;
}

.date-range-picker-toast .rc-calendar-body{
  border-bottom: none!important;
  padding: 0;
  height: auto;
}

.date-range-picker-toast .rc-calendar-prev-year-btn:after{
  content: none!important;
}

.date-range-picker-toast .rc-calendar-prev-month-btn:after{
  content: none!important;
}

.date-range-picker-toast .rc-calendar-next-year-btn:after{
  content: none!important;
}

.date-range-picker-toast .rc-calendar-next-month-btn:after{
  content: none!important;
}

.date-range-picker-toast .rc-calendar-range-middle{
  display: none;
}

.date-range-picker-toast .rc-calendar-header{
  text-align: left;
  padding: 0;
  border-bottom: none;
  margin-bottom: 21px;
}

.date-range-picker-toast .rc-calendar-month-select, .date-range-picker-toast .rc-calendar-year-select{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.date-range-picker-toast .rc-calendar-year-select{
  float: right;
}

.date-range-picker-toast .rc-calendar-clear-btn{
  display: none;
}

.date-range-picker-toast .rc-calendar-cell{
  width: 46.43px;
  height: 38.29px;
}

.date-range-picker-toast .rc-calendar-cell.rc-calendar-in-range-cell{
  background: #96C0FF;
}

.date-range-picker-toast .rc-calendar-selected-day .rc-calendar-date{
  border: none;
  background: none!important;
}

.date-range-picker-toast .rc-calendar-selected-day {
  background: #0029FF;
}

.date-range-picker-toast .rc-calendar-selected-day .rc-calendar-date{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.date-range-picker-toast .rc-calendar-selected-day.rc-calendar-today .rc-calendar-date{
  color: #FFFFFF;
}

.date-range-picker-toast .rc-calendar-date{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #000000;

}

.date-range-picker-toast .rc-calendar-today .rc-calendar-date{
  border: none;
  background: none;
  color: #000000;
}

.date-range-picker-toast .rc-calendar-date-panel{
  padding-bottom: 14px;
}

.date-range-picker-toast .rc-calendar-range{
  width: 784px;
  margin-top: 24px;
}

.date-range-picker-toast .rc-calendar-column-header-inner{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(60, 60, 67, 0.6);
}

//.date-range-picker-toast .rc-calendar-next-month-btn-day{
//  display: none;
//}

.date-range-picker-toast .rc-calendar-range-right{
  float: left;
}

.date-range-picker-toast .hints{
  width: 188px;
  height: 490px;
  float:left;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 29px;
}

.date-range-picker-toast .toast-body{
  padding: 0;
}

.date-range-picker-toast .hints div{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  height: 48px;
  text-align: center;
}

.date-range-picker-toast .bottom-panel{
  padding-left: 51px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.date-range-picker-toast .bottom-panel .ibutton:first-child {
  margin-right: 24px;
}

.date-range-picker-toast .bottom-panel .ibutton:last-child {
  margin-right: 11px;
}

.date-range-picker-toast .time-arrow{
  margin-top: 44px;
  margin-right: 25px;
  margin-left: 25px;
  float: left;
}

.timezone-picker {
  margin-left: 12px;
  margin-top: 36px;
}

.date-range-picker-toast .hints .timezone-picker div {
 font-size: 14px;
}

.date-range-picker-toast .hints .timezone-picker .btn{
  padding-right: 2px;
}

.date-range-picker-toast .hints .timezone-picker div.dropdown-menu{
  height: fit-content;

}

.date-range-picker-toast .timezone-picker .form-label {
  height: 24px;
}

.date-range-buttons{
  float: right;
  margin-top: 28px;
}

.date-range-picker-toast.single-date .rc-calendar-panel{
  width: 428px;
}

.date-range-picker-toast .rc-calendar-footer{
  display: none;
}
