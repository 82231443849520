.contact-support header {
  color: var(--black-white-bw-500, #01072C);

  /* Paragraph/Medium/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  align-self: stretch;

  margin-top: 24px;
}

.contact-support #support-ticket {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 32px;
}

.contact-support .severity-box {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
}

.contact-support .type-radio {
  display: flex;
  align-items: flex-end;
  column-gap: 8px;
  height: 24px;
}

.contact-support .type-radio input {
  border-radius: 50%;
  width: 24px;
  height: 24px;

  transition: 0.2s all linear;
}

.contact-support .right-icon {
  width: 12px;
  height: 14px;
  rotate: 270deg;
}
