.allow-block-checkboxes .checkbox {
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height: 48px;

  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-left: 10px;
  margin: 0px;
}

.allow-block-checkboxes {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
}

.allow-block-checkboxes .title {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01072C;
}

.geo_fencing .content {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
}

.geo_fencing .no-geo-placeholder {
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.geo_fencing .content .type {
  text-transform: capitalize;
}

.geo-fencing-confirm .short-summary * {
  text-align: left;
}

.geo-fencing-confirm .short-summary p {
  margin-top: 2em;
  margin-bottom: 0px;
  font-weight: 700;
}
