.switch input:checked{
  background: #0029FF no-repeat;
  border-radius: 50px;
  width: 44px !important;
  height: 24px;
  background-image: url("/assets/icons/switch_knob.svg") !important;
  background-size: 16px 16px;
  background-position: 24px 4px !important;
  cursor: pointer;
}

.switch input{
  background: #D9D9D9 no-repeat;
  border-radius: 50px;
  width: 44px !important;
  height: 24px;
  background-image: url("/assets/icons/switch_knob.svg") !important;
  background-size: 16px 16px;
  border: none;
  background-position: 4px 4px !important;
  cursor: pointer;
}
