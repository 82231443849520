.view-container.behaviors{
  display: flex;
  flex-direction: column;
  gap: 0;
}

.behaviors .behaviors-header {
  display: flex;
  margin-bottom: 16px;
  margin-top: 16px;
  flex-direction: row;
  justify-content: space-between;
}

.behaviors .behaviors-header .behaviors-header-text {

}

.behaviors .behaviors-header .behaviors-header-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: end;
}

.behaviors .behaviors-header .behaviors-header-text .behaviors-title {
  color: #000;

  /* Headline/H3 */
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
}

.behaviors-header-text .behaviors-description {
  width: 661px;
  color: #000;
  font-family: Rubik, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.behaviors-header-text .behaviors-description a {
  color: #0029FF;
  font-family: Rubik, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.behaviors-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.behaviors-body .behavior-box {
  padding: 16px 24px;
  border-radius: 5px;
  border: 1px solid #B0B2BE;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.behaviors-body .behavior-box.default{
  background: #F3F3F3;
}

.behaviors-body .behavior-box .expand-btn{
  position: absolute;
  left: -12px;
  border-radius: 5px;
  border: 1px solid #8F8F8F;
  background: #FFF;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.behaviors-body .behavior-box .behavior-name{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.behaviors-body .behavior-box .behavior-name-container{
  display: flex;
  align-items: center;
  gap: 8px;
}

.behaviors-body .behavior-box .short-display {
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
}

.behaviors-body .behavior-box .behavior-item-buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 11px;
  align-self: flex-end;
  justify-self: flex-end;
}

.behaviors-body .behavior-box .behavior-item-buttons img {
  cursor: pointer;
}

.behaviors-body .behavior-box .more-items {
  color:  #01072C;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  display: flex;
  width: fit-content;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #E6E6EA;
}

.behaviors-body .behavior-box .actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.behaviors-body .behavior-box .conditions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.behavior-condition {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-direction: row;
}
.behavior-condition .field {
  color: #09F;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  display: flex;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid #09F;
  background: #FFF;
}

.behavior-condition .operator {
  color: #01072C;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.behavior-condition .value {
  color:  #555972;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  max-width: 450px;
}

.behavior-action {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-direction: row;
}

.behavior-action .action-name{
  color: #7B61FF;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  display: flex;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #7B61FF;
  background: #FFF;
}

.behavior-action .action-value {
  color:  #555972;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  max-width: 400px;
}

.edit-behavior-title{
  color: #000;

  /* Headline/H3 */
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  text-transform: capitalize;

}

.edit-behavior-dialog .modal-body{
  margin-top:0;
  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.behavior-mode-chooser {
  margin-top: 34px;
  margin-bottom: 34px;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #0029FF;
  flex-direction: row;
  cursor: pointer;
}

.behavior-mode {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  background: #FFF;
  color: #3D5CFF;

  /* Subtitle/S1 */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.behavior-mode.selected{
  border-radius: 5px;
  background: #D1E3FF;
  color: #070707;
  text-align: center;

  /* Subtitle/S1 */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.behavior-form {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.behavior-form-separator {
  background: rgba(0, 0, 0, 0.20);
  height: 1px;
}

.condition-form-line {
  display: flex;
  gap: 16px;
  align-self: stretch;
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.condition-items {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.conditions-title{
  color: #000;

  /* Headline/H3 */
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
}

.and-or-marker {
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #0029FF;
  background: rgba(163, 200, 255, 0.50);
  width: 112px;
  color: #0029FF;

  /* Special/Button */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px; /* 0% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.and-or-buttons{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 24px;
  justify-content: center;
}

.action-form-line{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.action-form-line .delete-button{
  margin-top: 28px;
}

.cache-key-form-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 16px;
}

.action-form {
  margin-bottom: 8px;
  position: relative;
}

.header-form {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.edit-behavior-header{
  margin-bottom: 36px;
}

.actions-header{
  margin-bottom: 24px;
}

.actions-title{
  color: #000;

  /* Headline/H3 */
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
}

.basic-condition{
  position: relative;
}

.basic-condition .ibutton{
  position: absolute;
  right: 0;
  top:28px;

}

.expanded-conditions {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.expanded-marker{
  display: flex;
  width: 92px;
  height: 39px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  border: 1px solid #0E954D;
  background: #E7FFEF;
  color: #0E954D;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.expanded-condition-items{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.expanded-condition-or{
  border-radius: 5px;
  border: 1px solid  #B0B2BE;
  padding: 8px;
}

.and-item {
  display: flex;
  flex-direction: row;
  gap:10px;

  height: 32px;
  align-items: center;
  align-self: stretch;
}

.and-marker {
  display: flex;
  width: 62px;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #E7EAE6;
  color: #01072C;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.or-container {
  align-self: stretch;
}

.or-marker {
  display: flex;
  width: 62px;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #E7EAE6;
  color: #01072C;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  margin-top: 10px;
  margin-bottom: 10px;
}

.expanded-actions {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-top: 12px;
}
.expanded-action-items{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid  #B0B2BE;
  padding: 16px 16px;
  align-self: stretch;
  gap: 8px;
}

.behavior-draggable-container {
  display: flex;
  height: 48px;
  padding: 0 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #B0B2BE;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.drag-indicator-container{
  display: flex;
  width: 26px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-right: 1px solid #B0B2BE;
}

.drag-indicator-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.drag-indicator-container svg{
  height: 4.164px;
  flex: 1 0 0;
}

.drag-indicator-single-column {
  display: flex;
  align-items: center;
  gap: 2.61px;
  flex-direction: column;
}

.drag-indicator-point-container{
  display: flex;
  width: 4.164px;
  align-items: center;
  gap: 6.524px;
}

.reordered-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
}

.reorder-behaviors-title{
  color: #000;

  /* Headline/H3 */
  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  text-transform: capitalize;
}

.reorder-behaviors-description{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.reorder-behaviors-header {
  margin-bottom: 50px;
  margin-top: 32px;
}

.reorder-behaviors-dialog .modal-body{
  padding-top: 0;
}

.reorder-behaviors-dialog .modal-header{
  display: none;
}

.and-or-marker.or{
  margin-top: 40px;
  margin-bottom: 40px;
  justify-self: center;
  align-self: center;
}


.and-line {
  width: 112px;
  height: 48px;
  justify-content: center;
  display: flex;
}

.and-line div{
  width: 1px;
  height: 100%;
  border: 1px solid grey;
}

.delete-behavior-condition{
  margin-top: 0;
  cursor: pointer;
}

.delete-behavior-condition.first{
  margin-top: 28px;
}

.cache-key-form{
  margin-top: 18px;
}

.reorder-behavior-container{
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  flex-direction: row;
}

.behavior-index{
  color:  #01072C;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  display: flex;
  width: 20px;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #E6E6EA;
}

.add-behavior-action{
 justify-self: center;
}

.condition-form-line .multiSelectContainer .searchWrapper{
  height: 48px;
}

.condition-form-line .geo-select-container .multiselect-container .searchBox {
  width: 430px;
}

.flex-placeholder{
  align-self: stretch;
  width: 100%;
}

.date-range-picker-toast.deny-access-range {
  bottom: 67px;
  left: 0;
  margin-right: 0;
  top: revert;
  padding-bottom: 24px;
  width: 820px;
}

.date-range-picker-toast.deny-access-time {
  width: 600px;
  bottom: 67px;
  left: 0;
  margin-right: 0;
  top: revert;
  padding: 24px;
}

.deny-access-by-time-periodic{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date-range-picker-toast .bottom-panel {
  padding-left: 0;
}


.action-form .timezone-picker{
  margin-top: 0;
}

.date-range-picker-toast.deny-access-range .rc-calendar-range-part {
  margin-left: 0;
}
