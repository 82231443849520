
.multiselect-container .option, .multiselect-container .groupHeading{
  border-bottom: 2px solid #B0B2BE;
  padding-left: 16px !important;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #01072C;
  height: 56px;
}

.multiselect-container .search-wrapper {
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: 724px 14px;
  background-size: 10px 10px;
  background-image: url('/assets/icons/down_arrow.svg');
}

.multiselect-container ul{
  max-height: 450px;
}

.multiselect-container .groupHeading{
  height: 56px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
}

.multiselect-container .groupHeading:first-child{
  display: none;
}

.multiselect-container {
  margin-bottom: 10px;
}

.multiselect-container .chip{
  display: none;
}

.geo-select-container .multiselect-container .searchBox {
  width: 500px;
  padding-left: 42px;
}

.multiselect-container .optionListContainer{
  z-index: 1300;
}

.multiselect-container .searchBox::placeholder {
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.geo-select-container{
  position: relative;
}

.geo-select-container .search-icon{
  position: absolute;
  top: 10px;
  left: 16px;
}
