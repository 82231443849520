
.app-header {
  height: 134px;
  background: #01072C;
  color: #FFF;
  display: block;
  padding-top: 43px;
  min-width: 1440px;
  padding-left: 48px;
  z-index: 3000;
}

.app-header.drill-down{
  min-width: 1152px;
}

.app-header.drill-down {
  background: #FFFFFF;
  height: 112px;
  padding-top: 24px;
  padding-left: 8px;
}



.app-header.drill-down .logo{
  display: none;
}

.app-header h3 {
  display: none;
}

.app-header.drill-down h3, .view-container h3 {
  display: block;
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  width: fit-content;
}

.app-header nav {
  display: none;
}

.app-header.drill-down nav {
  display: block;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.app-header.drill-down nav a {
  text-decoration: none;
  color: #000000;
}
.app-header.drill-down nav .breadcrumb {
  margin-left: 2px !important;
}

.app-header.drill-down nav .breadcrumb-item.active {
  color: black;
}

.app-header.drill-down .navigation > *{
  display: none !important;
}

.header-nav.navigation {
  margin: auto;
  display: block;
}

.header-nav .nav-item{
  display: block;
  margin-left: 24px;
  padding: 18px 18px;
  height: 58px;
  cursor: pointer;
  float: left;
}

.header-nav .nav-item.dropdown{
  padding: 0;
}

.header-nav .nav-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  z-index: 10;
  float: left;
}

.header-nav .nav-item.active {
  border: 1px solid #fff;
  border-radius: 10px;
  height: 56px;
  padding-top: 17px;
  padding-left: 17px;
  padding-right: 17px;
}

.header-nav .nav-item:hover {
  border: 1px solid #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  height: 56px;
  padding-top: 17px;
  padding-left: 17px;
  padding-right: 17px;
}

.header-nav .nav-link{
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  text-transform: uppercase;
  padding: 0;
  float: left;
}

.header-nav .nav-link:hover{
  color: #FFFFFF;
}

.app-header h2{
  margin-top: 68px;
}

.app-header .background-vector{
  position: absolute;
  top:0;
  left: 10px;
  z-index: 2500;
  height: 134px;
  overflow: hidden;
}

.app-header.drill-down .background-vector{
  display: none;
}

.app-header .drop-down-toggle .s2{

  text-transform: none;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  float: left;
}

.app-header .drop-down-toggle .s2.user-name{
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-header .drop-down-toggle {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  padding-top: 8px;
  width: 184px;
  height: 56px;
  border: 1px solid #8F8F8F;
  border-radius: 10px;
}


.app-header.drill-down .drop-down-toggle {
  color: #000000;
}

.app-header .drop-down-toggle img{
  float: right;
  margin-top: 16px;
}

.app-header .drop-down-toggle .user-icon{
  margin-top: 2px;
  margin-right:12px;
  background: #3D5CFF;
  width: 36px;
  height: 36px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20.2222px;
  line-height: 34px;
  text-align: center;
  border-radius: 18px;
  text-transform: uppercase;
}

.app-header .greeting{
  padding-left: 12px;
}

.app-header.drill-down .greeting{
  display: none;
}

.nav-dropdown .btn{
  background: none;
  border: none !important;
  padding: 0;
}

.nav-dropdown .btn:hover{
  background: none;
  border: none;
}

.nav-dropdown ul{
  width: 184px;
  transform: translate3d(0px, 50px,0px) !important;
}

.app-header .tasks-icon {
  margin-right: 42px;
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 14px;
}

.app-header .vendor-status-icon {
  margin-right: 42px;
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 14px;
  width: 50px;
}

.app-header .vendor-status-icon.deploying {
  margin-top: 2px;
}

.app-header.drill-down .container-fluid {
  width: 100%;
  margin:0;
}

.app-header .header-shoulders {
  display: flex;
  width: 100%;
  padding-right: 33px;
  z-index: 2501;
}

.app-header.drill-down .header-shoulders{
  width: 1152px;
  min-width: 1152px;
  display: flex;
  margin: auto;
}

.app-header.drill-down .header-shoulders {
  padding-right: 0;
}

.tooltip-toast.vendors-state-tooltip {
  margin-top: 70px;
  width: 150px;
}

.tooltip-toast.vendors-state-tooltip .toast-body{
  padding-top: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.header.header-sticky{
  z-index: 20001;
}

img.logo {
  cursor: pointer;
}
