.cards-container {
  padding-top: 20px;
}

.icard  {
  padding: 0;

  width: 414px;
  height: 278px;

  background: #FFFFFF;

  box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.2);
  border-radius: 10px;
  margin-bottom: 40px;
  position: relative;
  border: 2px solid #E5EDFF;
}

.icard.with-tab{
  border-top-right-radius: 0;
}

.icard-tab{
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px 10px 0px 0px;
  border: 2px solid #E5EDFF;
  background: var(--grad-dark-blue, linear-gradient(90deg, #01072C 0%, #182574 100%));
  position: absolute;
  top: -43px;
  right: -2px;
}


.icard-tab .tooltip-toast .toast-body {
  padding-top: 10px;
}

.icard-tab .tooltip-toast{
  margin-top: -160px;
}

.icard

.icard-tab-text{
  color: var(--black-white-bw-0, #FFF);
  text-align: center;

  /* Headline/H6 */
  font-family: Rajdhani, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  margin-right: 10px;
}

.icard-tooltip-pointer{
  position: absolute;
  right: 20px;
  rotate: 180deg;
  top: -10px;
  z-index: 1000001;
}

.icard  .header{
  display: flex;
  padding: 24px;
  padding-right: 14px;
  
  background: linear-gradient(90deg, #01072C 0%, #182574 100%);
  border-radius: 10px 10px 0 0;
  color: #FFF;
  min-height: 76px;
}

.icard.with-tab  .header{
  border-top-right-radius: 0;
}

.icard .header .title{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
  max-width: 410px;
  min-width: 0;
}

.icard .header img.menu-icon{
  width: 5px;
  height: 16px;
  cursor: pointer;
}

.icard .header .title img,
.icard .header img.menu-icon {
  height: 30px;
}

.icard  .header .title h5{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  margin-bottom: 0px;
  max-width: 260px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icard .body {
  color: #000;
  padding-left: 24px;
  padding-top: 18px;
  float: left;
  width: 100%;
}

.icard .header-right{
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
}

.icard  .body div{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
}

.icard .body .s2{
  margin-top: 12px;
  text-transform: none;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.icard  .badge {
  float: right;
  height: 24px;
  padding: 7px;
  line-height: 10px;
  box-sizing: border-box;
}

.icard .body-container{
  position: relative;
}

.icard .body-container .actions {
  float:right;
  display: none;
}

.icard:hover .body-container .actions {
  float:right;
  display: block;
  margin-right: 15px;
  margin-top: 15px;
  position: absolute;
  right: 15px;
}

.card-action {
  text-align: center;
  width: 48px;
  height: 48px;

  background: #FFFFFF;
  border: 1px solid #E5EDFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding-top: 10px;
  cursor: pointer;
  margin-bottom: 14px;
}

.icard .tooltip-badge{
  position: absolute;
  right: -273px;
  top: 50px;
  white-space: pre-line;
}

.icard .tooltip-badge .toast-body{
  padding-right: 16px;
}

