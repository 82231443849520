.onboarding-tips {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
}

.onboarding-tips .ribbon {
  display: flex;
  height: 64px;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 5px;
  background: #004EFF;
}

.onboarding-tips .ribbon .title {
  flex-shrink: 0;
  
  color: #F4F6F8;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.onboarding-tips .ribbon .subtitle {
  color: #F4F6F8;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.onboarding-tips .group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.onboarding-tips .ibutton button {
  color: #FFF;

  /* Special/Button */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px; /* 0% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.onboarding-tips .ibutton.secondary button {
  display: flex;
  padding: 20px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 5px;
  border: 2px solid #FFF;
}