.cache-management .action-row {
  padding-right: 0;
}

.purge-path {
  width: 932px;
  margin-right: 10px;
}

.purge-form{
  height: 98px;
}

.purge-paths-cell {
  max-width: 500px;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
