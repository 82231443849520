
.data-table table {
  margin-top: 47px;
}

.data-table th {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  height: 56px;
  padding-left: 32px !important;
  border-bottom: 3px solid #E5EDFF !important;
}

.data-table.sorted th {
  background: #E5EDFF;
  cursor: pointer;
}

.data-table.sorted th img.sort {
  float: right;
  margin-top: 8px;
}

.data-table.sorted th img.sort.up{
  transform: rotate(180deg);
}

.data-table td {
  font-family: 'Rubik', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  height: 80px;
  padding-left: 32px !important;
  padding-right: 24px;
  padding-top: 8px !important;
  vertical-align: middle;
}

.service-providers .data-table td{
  border-right: none;
}

.filter-dropdown{
  margin-right: 16px;
}

.data-table td img.table-expanded{
  transform: rotate(90deg);
}

.data-table td {
  border-bottom: 3px solid #E5EDFF !important;
  border-right: none;
}

.data-table td:last-child{
  border-right: none;
}

.data-table tr.expanded-row {
  border-bottom: 3px solid #9EBCFF;
  height: 172px;
}

.data-table tr.expanded-row-highlighted {
  border-bottom: 3px solid #9EBCFF !important;
  height: 157px;
}

.data-table tr.expanded-row-highlighted * {
  background-color: #F6F8FF;
}

.data-table .expanded-row-highlighted h1 {
  color: #000;
  font-family: Rajdhani;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.log-expanded {
  padding-top: 24px;
  padding-left: 36px;
  padding-right: 36px;
}

.log-expanded .row-details{
  float: left;
  margin-right: 128px;
}

.log-expanded .row-details label{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  width: 69px;
  margin-right: 8px;
}

.audit-logs .log-expanded .row-details label{
  width: 80px;
}

.log-expanded .row-details {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.log-expanded .log-details {
  height: 103px;
  width: 551px;
  background: rgba(239, 239, 239, 0.6);
  border-radius: 5px;
  float: left;
  padding: 16px;
}

.log-expanded .audit-change-section{
  float: left;
  margin-right: 29px;
}

.log-expanded .audit-change{
  width: 379px;
  height: 584px;

  background: #E8F5FF;
  border-radius: 10px;
  padding-top: 24px;
  padding-left: 24px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.log-expanded .audit-change pre{
  height: 570px;
}

img.table-expanded, img.table-collapsed{
  cursor: pointer;
}

.paginated-table-container {
  height: auto;
}

.data-table td.align-center {
  text-align: center;
}

.table .pending {
  background: #FFFBE7;
}

.logs .date-range-picker {
  margin-top: 32px;
}

//.data-table .dragging td{
//  transform: scaleX(100%);
//}

.data-table td.draggable{
  padding-left: 0 !important;
}

.data-table td.draggable .drag-icon {
  margin-right: 10px;
  float: left;
}

.table-loading-container {
  height: 0;
}

.table-loading{
  position: relative;
  top: 90px;
  text-align: center;
}


.edit-in-table{
  margin-right: 10px;
}

.table > :not(caption) > * > * {
  border-bottom: none !important;
}

.data-table tr{
  border-bottom: 3px solid rgb(216, 219, 224);
}

.table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgb(216, 219, 224);
}
