.health-check {
  float: left;
}

.health-check .icheckbox{
  margin-left: 20px;
  margin-right: 20px;
}

table .provider-logo {
  margin-right:8px;
}

.service-exists-warning {

}

.traffic-management .action-row .idropdown button{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.traffic-management .action-row .add-provider-btn button{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
