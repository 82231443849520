.tooltip-toast .toast-header{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 16px;
  border: none;
  position: relative;
  left: -26px;
  background: none;
}

.tooltip-toast {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
  border: none;
  height: auto;
  position: fixed;
  z-index: 1000000;
  background: #FFFFFF;
  margin-top: 18px;
}

.tooltip-toast .tooltip-pointer {
  position: relative;
  top: -30px;
  left: 26px;
}

.tooltip-toast .no-title{
  top: -15px;

}


.tooltip-toast .tooltip-icon {
  margin-right: 10px;
}

.tooltip-toast .toast-body {
  height: auto;

  /* Body/Small */

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding: 0 48px 16px 16px;
}
