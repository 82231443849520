.badge {
  line-height: 21px;
  height: 28px;
  text-transform: uppercase;
  border-radius: 5px;
  font-family: Rubik, sans-serif;
  font-size: 12.25px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.98px;
  display: inline-flex;
}

.badge.error {
  background: #FF9F9F;
  color: #950000;
}

.badge.warning {
  background: #FFE142;
  color: #6A4000;
}

.badge.partial {
  background: #FFE142;
  color: #6A4000;
}

.badge.self_managed{
  background: #B8FF9F;
  color: #005508;
}

.badge.valid{
  border-radius: 4.375px;
  border: 1px solid #9FFBA8;
  background: #0DCB20;
  color: #C5FFCA;
}

.badge.expired{
  border-radius: 4.375px;
  border: 1px solid #FF4949;
  background: #FF4949;
  color: #FFF;
}


.badge.validating, .badge.failed, .badge.pending{
  display: flex;
  height: 24px;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid #FFF6C6;
  background: #FFE142;
  color: #6A4000;
}

.badge.managed{
  background: #B8FF9F;
  color: #005508;
}

.badge.external{
  background: #B8FF9F;
  color: #005508;
}

.badge.read_only {
  background: #B8FF9F;
  color: #005508;
}

.badge.active {
  background: #B8FF9F;
  color: #005508;
}

.badge.full {
  background: #B8FF9F;
  color: #005508;
}

.badge.in_progress {
  background: rgba(176, 189, 255, 0.23);
  color: #01072C;
}
