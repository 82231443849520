.behavior-actions-container {
  height: 450px;
  overflow: scroll;
}

.behavior-action-row > * {
  margin-right: 5px;
}

.behavior-action-row .delete-button {
  margin-top: 28px;
}

.behavior-action-row .no-margin {
  margin: 0;
}

.behavior-actions-container .checkbox {
  width: 379px;
}
.behavior-actions-container .checkbox input {
  float: left;
}

.checkbox input{
  float: left;
  width: 64px;
  height: 64px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  -webkit-appearance: none;
  outline: none;
}

.behavior-actions-container .checkbox input:checked{
  background-size: 21px 15px;
  background: #0029FF url("/assets/icons/checkbox_checkmark.svg") no-repeat 22px 22px;
}

.behavior-action-row .icheckbox {
  margin-right: 10px;
}

.behavior-action-row {
  border-top: 2px solid rgba(0, 41, 255, 0.2);
  padding-top: 56px;
  margin-top: 50px;
  display: flex;
}

.behavior-action-row.cache_key{
  flex-wrap: wrap;
}

.behavior-action-row:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.cache-key-row {
  height: 110px;
}

.cache-key-type {
  float: left;
}

.headers-value {
  float: left;
  margin-left: 16px;
  width: 400px;
  min-height: 92px;
}

.cache-key-placeholder{
  width: 100%;
}

.cache-key-badges{
  width: 400px;
}

.cache-key-badge {
  background: #EDF0FF;
  border-radius: 4px;
  float: left;
  margin-right: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  padding: 8px;

  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #101010;
  cursor: pointer;
}

.cache-key-badge img{
  margin-right: 10px;
}

.behavior-actions-container{
  position: relative;
}

.add-behavior-action{
  position: relative;
}

.behavior-action-row.auto_minify .icheckbox .on{
  width: 48px !important;
  height: 48px !important;
  background: #0029FF url("/assets/icons/checkbox_checkmark.svg") no-repeat 14px 14px;
}

.behavior-action-row.auto_minify .icheckbox .single-checkbox{
  padding: 0;
  width: 48px;
  height: 48px;
}

.behavior-action-row.auto_minify .icheckbox .off{
  width: 48px !important;
  height: 48px !important;
}

.default-behavior-row td{
  background: #FFF8DF;
}

.allowed_methods_container {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.allowed_methods_container .icheckbox{
  width: 51px;
}

.allowed_methods_container .form-label{
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.edit-behavior-dialog .modal-dialog.modal-dialog-centered.modal-lg{
  width: 1024px;
  max-width: 1024px;
}

.additional-paths{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.additional-paths .ibutton{
  margin-top: 28px;
  margin-left: 8px;
}

.behaviors-table .patterns{
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
}
