.billing .green-text {
  color: #1B8312;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.billing .action-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0px;
}

.billing .action-row .action-label-icon {
  margin-right: 4px;
  margin-bottom: 4px;
}

.billing .action-row .notice {
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 5px;
  background: #F4F4F4;

  margin-top: 28px;

  color: #041332;

  /* Body/Large */
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.billing .action-row .notice img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.billing > .section {
  margin-top: 32px;
}

.billing .action-row .filter {
  margin-bottom: 0px;
}

.billing .report {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;
  margin-top: 18px;
}

.billing .report .monthly-consumption {
  width: 100%;
}

.billing .report .monthly-consumption .service {
  display: flex;
  gap: 8px;
  align-items: center;
}

.billing .report .commitment {
  width: 270px;
}

.billing .report .carousel {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 10px 19px 34px 0px rgba(177, 193, 216, 0.80);
}

.commitment-card {
  width: 270px;

  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.commitment-card .card-body {
  padding: 0px;
}

.commitment-card .divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.commitment-card .providers {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.commitment-card .item {
  display: flex;
  justify-content: space-between;
}
