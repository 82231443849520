.add-rule{
  margin-left: 16px;
}
.access-logs .paginated-table-container{
  position: relative;
}

.access-logs .paginated-table-container .table-loading{
  position: absolute;
  width: 100%;
  top: 90px;
}

.access-logs .table-title {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 12px;
}

.edit-access-log  .scrollable{
  height: calc(100vh - 205px);
  overflow-y: scroll;
}

.access-logs .paginated-table-container {
  min-height: 287px;
  margin-bottom: 64px;
}

.edit-access-log .edit-title {
  margin-bottom: 20px;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  float: left;
}

h3.edit-title {
  margin-top: 36px;
}

.edit-access-log .edit-header {
  height: 90px;
  margin-top: 10px;
  padding: 20px 0;
}

.edit-access-log .form-text-field, .edit-access-log .idropdown{
  margin-bottom: 58px;
}

.edit-access-log .edit-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  height: 88px;
  width: 1152px;
  padding-top: 20px;
}

.edit-access-log .edit-footer .ibutton:first-child{
  margin-left: 8px;
}

.edit-access-log .edit-footer .form-error-message{
  width: 528px;
  height: 48px;
  line-height: 48px;
  background-position: 24px 14px;
}

.edit-access-log .body-hint{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #01072C;
  margin-bottom: 22px;
}

.methods-checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 336px;
  gap: 42px;
  margin-bottom: 36px;
}

.methods-checkboxes .icheckbox{
  width: 123px;
  margin-right: 24px;
  margin-top: 12px;
}

.methods-checkboxes .icheckbox .single-checkbox{
  padding: 0;
  width: 123px;
  height: 36px;
}

.methods-checkboxes .icheckbox .off{
  width: 36px !important;
  height: 36px !important;
}

.methods-checkboxes .icheckbox .on{
  width: 36px !important;
  height: 36px !important;
  background: #0029FF url("/src/assets/icons/checkbox_checkmark.svg") no-repeat 8px 10px;
}
