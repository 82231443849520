.chart-wrapper:hover .chartjs-tooltip{
  background: #FFFFFF !important;
  opacity: 1 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.chartjs-tooltip-header {
  //display: none !important;
}

.chartjs-tooltip-body tr:last-child td {
  border-bottom: none !important;
}

.chartjs-tooltip-body tr td span{
  width: 7px !important;
  height: 20px !important;
  border-radius: 50px !important;
  margin-bottom: -4px;
  opacity: 1 !important;
}

.chartjs-tooltip-header th{
  color: #000000;
}

.chartjs-tooltip-body tr td{
  color: #000000;
}
