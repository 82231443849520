.time-field .time-input{
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  width: 149px;
  height: 56px;
}

.time-field .time-input .hours{
  float: left;
  width: 62px;
  height: 51px;
  border: none;
  text-align: center;
}

.time-field .time-input .minutes{
  float: left;
  width: 62px;
  height: 51px;
  border: none;
  text-align: center;
}

.time-field .time-input .divider{
  float: left;
  margin-top: 14px;
  margin-right: 8px;
  margin-left: 8px;
}

.time-label{
  color: var(--black-white-bw-500, #01072C);

  /* Body/Small */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
