.waf-custom-rules-title-section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 241px;
  margin-bottom: 48px;
}

.condition-line {
  height: 76px;
}

.condition-field, .condition-operator, .condition-value, .json-field{
  float: left;
  margin-right: 16px;
}

.condition-button{
  float:left;
  margin-right: 8px;
  margin-top: 16px;
}

.condition-operator-button button{
  color: #0029FF !important;
  margin-bottom: 16px;
  margin-top: 16px;
  border: 1px solid #0029FF !important;
}

.waf-custom-rules-trigger-section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 48px;
}

.add-condition-buttons {
  height: 112px;
}

.waf-custom-rules-action-section{
  margin-bottom:48px;
  height: 160px;
}

.waf-custom-rules-counter-section{
  margin-bottom:48px;
  height: 180px;
}

.waf-custom-rules-expression-preview-section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 93px;
  margin-bottom: 24px;
}

.waf-custom-rules-expression-preview{
  background: rgba(163, 200, 255, 0.21);
  border-radius: 10px;
  padding-top: 18px;
  padding-left: 25px;
  padding-bottom: 17px;
}

.waf-custom-rules-expression-preview div{
  max-width: 900px;
  overflow-wrap: anywhere;
}

.waf-custom-rules-submit-section .ibutton {
  margin-left: 8px;
}

.waf-title{
  margin-top: 46px;
}

.filter-field{
  margin-left: 8px;
}

.managed-rule-line {
  border: 1px solid rgba(163, 200, 255, 0.8);
  border-radius: 10px;
  height: 56px;
  margin-bottom: 8px;
}

.managed-rule-line .switch{
  float: left;
  margin-left: 36px;
  margin-top: 16px;
  margin-right: 16px;
}

.managed-rule-title {
  float: left;
  margin-top: 18px;
}

.managed-rule-msg {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.managed-rule-id{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
}

.managed-rule-tags {
  float: right;
  margin-top: 18px;
}

.managed-rule-tag {
  float: left;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0029FF;
  background: rgba(163, 200, 255, 0.5);
  border-radius: 2px;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.paranoia-level-input {
  float: left;
  margin-right: 16px;
}

.managed-ruleset-inputs {
  height: 80px;
}

.waf-custom-rules-submit-section .form-error-message{
  width: 528px;
}

.waf-managed-ruleset-submit-section .ibutton:first-child{
  margin-left: 8px;
}

.waf-custom-rules-counter-section .float-start, .waf-custom-rules-action-section .float-start{
  margin-right: 16px;
}

.waf-custom-rules-overview .list-container{
  position: relative;
}

.rate-limit-custom-rules-overview .paginated-table-container {
  position: relative;
  height: 600px;
}

.rate-limit-custom-rules-overview .table-scroller{
  height: 500px;
  display: block;
  overflow: auto;
  width: 100%;
}

.waf-custom-rules-overview .table-scroller{
  height: 300px;
  display: block;
  overflow: auto;
  width: 100%;
}

.rate-limit-custom-rules-overview .table-loading, .waf-custom-rules-overview .table-loading {
  width: 100%;
  position: absolute;
  top: 80px;
}

.waf-custom-rules-overview .edit-footer, .rate-limit-custom-rules-overview .edit-footer {
  padding-bottom: 10px;
  height: 64px;
  position: absolute;
  bottom: 0;
}

.reorder-button{
  margin-right: 8px;
}

.list-rule-value {
  width: 624px;
  float: left;
}

.list-rule-value .multiselect-container .chip{
  //display: inline-flex;
  //background: #0029FF;
  //border-radius: 5px;
}

.list-rule-value .ibutton {
  float: left;
  position: relative;
  z-index: 10000000000;
}

.list-rule-value .ibutton button{
  height: 36px;
  margin-left: 6px;
  line-height: 24px;
  margin-top: 6px;
}

.list-rule-value .ibutton img {
  margin-top: 4px;
}

.ibutton.condition-operator-button button:disabled{
  background: rgba(163, 200, 255, 0.5);
}

.managed-rules-list{
  margin-top: 14px;
}

.delete-condition{
  float: left;
  margin-right: 10px;
  margin-top: 29px;
}
