
.form-text-field {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height:48px;
  align-items: stretch;
}

.form-text-field .field-unit {
  right: 12px !important;
  position: absolute;
  margin-top: 11px;
  color: var(--neutral-900, #111827);
}
.form-text-field .clipboard {
  margin-top: -2px;
  margin-left: 10px !important;
}

.form-text-field .clipboard button{
  border-radius: 0 10px 10px 0;
  right: -10px
}

form .form-label {
  color: var(--black-white-bw-500, #01072C);
  /* Subtitle/S2 */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin-bottom: 8px;
}

form .form-label[disabled]
{
  pointer-events: none;
  opacity: 0.2;
}

.view-container .form-label{
  color: black;
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.form-text-field:hover {
  background: rgba(255, 255, 255, 0.1);
}

.form-text-field .icon-container{
  background: transparent;
  border: none;
  color: white;
}

.form-text-field.valid{
  border-color:#1EC92F;
}

.form-text-field.disabled{
  border-color: #B0B2BE;
  opacity: 0.2;
}

.form-text-field.error{
  border-color:#D12F2F;
}

.form-text-field.error input{
  background-image: url('/assets/icons/exclamation.svg') !important;
}

.form-text-field input {
  border: none;
  padding-left: 0;
  width: 1%;
  position: relative;
  flex: 1 1 auto;

  background: transparent no-repeat right calc(0.375em + 0.1875rem) center !important;
  background-size: 20px 20px;

}


textarea:focus, input:focus{
  outline: none;
}

.form-text-field.valid input {
  border-color:#1EC92F;
  background-image: url('/assets/icons/form_checkmark.svg');
}

.validation-error {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #D12F2F;
  margin-bottom:16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,input:-internal-autofill-selected,input:-internal-autofill-previewed  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}


@keyframes autofill {
  100% {
    background: transparent !important;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent !important;
    color: inherit;
  }
}

.form-text-field input:-internal-autofill-previewed{
  background: transparent !important;
}

.form-text-field input:-internal-autofill-selected {
  background:transparent !important;
}

.action-row .ibutton{
  margin-top: 28px;
}

.modal .form-label{
  color: #000000;
}

.modal h6 {
  color: #000000;
}

.modal .s1{
  color: #000000;
}

.modal .s3{
  color: #000000;
}

.form-text-field.textarea {
  height: 144px;
  padding-left: 24px;
}

.form-text-field textarea {
  width: 100%;
  border:none;
  height: 125px;
  resize: none;
  padding-top: 16px;
  padding-right: 16px
}


.modal input:-webkit-autofill,
.modal input:-webkit-autofill:hover,
.modal input:-webkit-autofill:focus,
.modal input:-webkit-autofill:active,
.modal input:-internal-autofill-selected  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #000 !important;
}


form input:focus,form  textarea:focus,form  select:focus,form  button:focus,form  div:focus{
  outline: none !important;
}

.form-error-message {
  border-radius: 5px;
  width: 100%;
  min-height: 64px;
  background-color: #FFD600;
  background-repeat: no-repeat;
  background-position: 24px 20px;
  background-size: 20px 20px;
  background-image: url('/assets/icons/exclamation.svg');
  padding-left: 52px;
  margin-bottom: 10px;
  color: #782C00;


  display: flex;
  align-items: center;
}


.ml-5{
  margin-left: 5px;
}

.form-field-hint {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #B0B2BE;
}

.form-title-hint{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 34px;
  width: fit-content;
}

.PhoneInput{
  margin-bottom: 16px;
}

.PhoneInput input{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height:48px;
  align-items: stretch;
}

.react-date-picker{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 5px;
  height:48px;
  align-items: stretch;
  padding: 8px 24px;
  width: 176px;
  background: #E5EDFF;
}

.modal-body .react-date-picker__wrapper{
  border: none;
}

.react-date-picker__inputGroup__leadingZero{
  color: #000000;
}

.react-date-picker__inputGroup__divider{
  color: #000000;
}

.react-date-picker__clear-button{
  display: none;
}

.required-asterisk{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: #FF3D3D;

}

.form-text-field input.list-badge {
  background: #EDF0FF !important;
  border-radius: 4px !important;
  width: auto;
  flex: none;
  height: 36px;
  align-items: normal;
  margin-top: 4px;
  padding-left: 8px;
}
