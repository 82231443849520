.service-card {
  width: 419px;
  height: 574px;

  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 19px 34px rgba(177, 193, 216, 0.8);
  border-radius: 10px;
  color: black;
  position: relative;
  margin-bottom: 64px;
}

.service-card .border-overlay{
  border: none;
  border-radius: 10px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  cursor: pointer;
}

.service-card .border-overlay.hover {
  border: 2px #0066C0 solid;
  box-shadow: 35px 36px 34px rgba(177, 193, 216, 0.8);
}

.service-card .border-overlay:hover {
  border: 2px #0066C0 solid;
  box-shadow: 35px 36px 34px rgba(177, 193, 216, 0.8);
}

.service-card .body{
  cursor: pointer;
}

.service-card:hover .border-overlay{
}

.service-card.list {
  width: 100%;
  height: 81px;
  margin-bottom: 24px;
}

.service-card.list .chart-container{
  display: none;
}

.service-card .service-card-header {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 28px;
  position: relative;
}

.service-card.list .service-card-header {
  padding-top: 24px;
}

.view-container.services {
  padding-bottom: 48px;
}

.service-card .service-title{
  font-family: "Rajdhani", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  float: left;
  width: 20ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.service-card .expanded-service{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  float: none;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  width: 20ch;
  height: 74px;

}

.service-card .service-badges {
  margin-top: 16px;
  height: 24px;
}

.service-card.list .service-badges{
  margin-top: 8px;
  margin-left: 40px;
  float: left;
  width: 86px;
}

.service-card .service-badges .badge {
  margin-right: 16px;
  height: 24px;
  line-height: 18px;
}

.service-card .service-badges .service-cname {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: #E5F3FF;
  border-radius: 5px;
  padding-left: 8px;
  height: 24px;
  padding-right: 8px;
  position: relative;
  z-index: 2019;
  margin-top: 4px;
  width: fit-content;
}

.service-card.list .service-badges .service-cname {
  display: none;
}

.service-card .service-badges .service-cname img{
  margin-left: 10px;
  margin-top: -2px;
  cursor: pointer;
}

.service-card .title-row {
  height: 36px;
}

.service-card.list .title-row{
  width: 258px;
  float: left;
}

.service-card .menu-button{
  width: 24px;
  height: 40px;

  background: #FFFFFF;
  border: 1px solid #BCBCBC;
  border-radius: 10px;
  float: right;
  padding-top: 7px;
  padding-left: 9px;
  cursor: pointer;
  position: absolute;
  z-index: 2002;
  right: 25px;
  top: 24px
}

.service-card.list .menu-button{
  position: absolute;
  float: none;
  right: 20px;
  z-index: 2003;
}

.service-card .metrics {
  padding-left: 24px;
  margin-top: 68px;
  height: 60px;
}

.service-card.list .metrics {
  float: left;
  padding-left: 0;
  margin-top: 0;
  height: 41px;
  margin-left: 15px;
}

.service-card.list .metrics .metric-title{
  display: none;
}

.service-card .metrics .metric {
  float: left;
  margin-right: 25px;
}

.service-card .metrics .metric .metric-title {

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;

  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 7px;
}

.service-card .metrics .metric .metric-value {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 7px;
}

.service-card.list .metrics .metric .metric-value {
  min-width: 120px
}

.service-card .metrics .metric .metric-value.error {
  color: #D00005;
}

.service-card .metrics .metric-value.no-error {
  color: #179023;
}

.service-card .metrics .metric .metric-subtitle {

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;

  color: rgba(0, 0, 0, 0.5);
}

.service-card .providers{
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 41px;
}

.service-card.list .providers{
  float: left;
  margin-top: 0;
  margin-left: 68px;
}

.service-card.list .provider-row{
  width: 80px;
  padding-top: 0;
}

.service-card.list .providers .provider-row .provider-portion-pct{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 24px;
  float: none;
}

.service-card.list .providers .provider-border{
  display: none;
}

.service-card .provider-row{
  padding-top: 8px;
  height: 40px;
}

.service-card .provider-row .provider-name {
  float: left;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.service-card .provider-row .provider-name-sub {

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.service-card.list .provider-row .provider-name{
  display: none;
}

.service-card .provider-row .provider-portion-container {
  float: right;
  border: 4px solid rgba(0, 133, 255, 0.1);
  border-radius: 9px;
  width: 18px;
  height: 18px;
}

.service-card .provider-row .provider-portion-container {
  float: right;
  border: 4px solid rgba(0, 133, 255, 0.1);
  border-radius: 9px;
  width: 18px;
  height: 18px;
}

.service-card .provider-row .provider-portion-pct {
  float: right;
  width: 52px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: right;
}

.service-card .provider-row img{
  margin-top: -13px;
}

.service-card .chart-container{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.service-card .chart-container .chart-wrapper {
  z-index: 20000;
  position: relative;
}

.service-card .drill-down {
  z-index: 100000;
  display: none;
  position: absolute;
  width: 419px;
  height: 459px;
  left: 0;
  top: 115px;
  background: #215EDF;
  opacity: 0.95;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.service-card.hover .drill-down{
  display: block;
}

.service-card.list:hover .drill-down{
  display: none;
}

.service-card .drill-down-rect{
  position: absolute;
  top: 101px;
  left: 131px;
}

.service-card .drill-down-arrow{
  position: absolute;
  top: 150px;
  left: 170px;
}

.service-card .drill-down-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;
  margin-top: 399px;
}

.services .list-icon{
  float: left;
  margin-top: 28px;
  margin-right: 10px;
  margin-left: 24px;
  cursor: pointer;
}

.services .cards-icon {
  float: left;
  margin-top: 28px;
  margin-right: 10px;
  cursor: pointer;
}

.service-menu {
  border: 1px solid #31363E;
  border-radius: 10px;
  width: 176px;
  height: 192px;
  margin-left: -135px;
  margin-top: -32px;
  background: #FFFFFF;
  position: absolute;
  z-index: 2020;
  right: 0;
  top: 50px;
}

.service-menu .service-menu-item {
  padding: 8px;
  border-bottom: 1px solid #31363E;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  z-index: 2021;
}

.service-menu .service-menu-item.disabled {
  color: gray;
}

.service-menu .service-menu-item:hover{
  background: #E6F0FF;
}

.service-menu .service-menu-item:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.service-menu .service-menu-item:last-child {
  border-bottom: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.services .list-headers {
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 20px;
  margin-top: 24px;
}

.services .list-headers div{
  cursor: pointer;
}

.services .list-headers div {
  float: left;
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  min-width: 120px
}

.services .list-headers div.service-name-title {
  width: 362px;
  cursor: pointer;
}

.services .sort {
  margin-left: 10px;
  cursor: pointer;
}

.services .list-headers div.hits-title {
  margin-right: 22px;
  margin-left: 100px;
}

.services .list-headers div.bytes-title {
  margin-right: 22px;
}

.services .list-headers div.errors-title {
  margin-right: 123px;
  width: 80px;
}

.services .title {
  float: left;
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #060606;
  margin-top: 28px;
  padding-right: 24px;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}

.services .action-row{
  padding-left: 12px;
  padding-right: 12px;
}

.service-card .grid-image {
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 10px;
  z-index: 500;
}

.service-card .grid-mask {
  position: absolute;
  bottom: 0;
  z-index: 501;
}

.service-card .chartjs-tooltip{
  display: none !important;
}

.service-card .provider-portion-doughnut{
  float: right;
  margin-top: 4px;
  margin-right: 15px;
}

.service-card.list  .provider-container{
  float: left;
  margin-right: 19px;
}


.ipv6 label {
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-left: 24px;
}

.hint {
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 24px;
}
.ipv6 input{
  transform: scale(2);
  margin-bottom: 20px;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.protocol-checkbox-label {
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.ipv6 .type-radio{
  padding-left: 9px;
}


.services .sort.up{
  transform: rotate(180deg);
}


#new_service_form .multiselect-container .searchBox {
  height: 34px;
}


#new_service_form .multiselect-container .icon_down_dir {
  right: 19px;
  width: 14px;
  float: right;
  display:block;
}
