.service-import-wizard-container .wizard-buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 88px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 24px;
  background: #FFFFFF;
}

.service-import-wizard-container .wizard-buttons .ibutton:first-child{
  margin-left: 10px;
}

.view-container.service-import-wizard-container{
  padding: 0;
  margin: 0;
  width: 100%;
  color: #000000;
}

.service-import-wizard-container .provider-wizard-step{
  width: fit-content;
}

.service-import-wizard-container {
  color: #000000;
}

.service-import-wizard-container .provider-wizard-step {
  margin: auto;
}

.service-import-wizard-container h1{
  margin: auto;
}

.service-import-wizard-container h4 {
  margin-bottom: 53px;
}

.service-import-wizard-container .step-container {
  display: table-cell;
  height: calc(100vh - 338px);
  text-align: center;
  vertical-align: middle;
  width: 100vw;
  margin: auto;
  padding-bottom: 64px;
}

.service-import-wizard-container .services-wizard-step{
  margin: auto;
  width: 1440px;
  height: 330px;
}

.service-import-wizard-container .services-wizard-step .service-checkbox{
  float: left;
  width: 419px;
  height: 68px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(90deg, #01072C 0%, #182574 100%);
  border: 1px solid #B0B2BE;
  border-radius: 5px;
  margin-bottom: 40px;
  margin-right: 24px;
  margin-left: 33px;
  padding: 2px 24px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 63px;
}

.service-import-wizard-container .services-wizard-step .service-checkbox .icheckbox {
  margin-top: 8px;
}

.service-import-wizard-container .selected-services-number{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
  float: right;
  margin-right: 16px;
}

.service-import-wizard-container .steps-indicator{
  position: fixed;
  bottom: 36px;
  width: 58px;
  margin: auto;
  height: 16px;
  left: calc(100vw / 2 - 29px);
}

.service-import-wizard-container .steps-indicator div{
  background: #E5EDFF;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  float: left;
  margin-right: 5px;
}

.service-import-wizard-container .steps-indicator div.current{
  background: #01072C;
}

.service-import-wizard-container .steps-indicator div:last-child{
  margin-right: 0;
}

.service-import-wizard-container .service-import-status {
  width: 1366px;
  min-height: 68px;
  border: 1px solid #B0B2BE;
  border-radius: 5px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
}

.service-import-wizard-container .service-import-status .title-row{
  padding: 14px 24px;
}

.service-import-wizard-container .service-import-status .status-badge{
  float: right;
  text-transform: uppercase;
  border-radius: 4.375px;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12.25px;
  padding: 7px;
  height: 24px;
  line-height: 10px;
}

.service-import-wizard-container .service-import-status .status-badge.partial {
  background: #FFE604;
  border: 1px solid #E7CA00;
  color: #534100;
}

.service-import-wizard-container .service-import-status .status-badge.full {
  background: #0DCB20;
  border: 1px solid #9FFBA8;
  color: #C5FFCA;
}

.service-import-wizard-container .service-import-status .status-badge.failed {
  background: #FF9F9F;
  border: 1px solid #FF4949;
  color: #C50202;
}

.service-import-wizard-container .service-import-status .status-title{
  float: left
}

.service-import-wizard-container .service-import-status .expand-icon{
  float: left;
  margin-right: 18px;
  margin-top: 10px;
  cursor: pointer;
}

.service-import-wizard-container .review-step{
  width: 1440px;
  margin: auto auto 88px;
}

.service-import-wizard-container .service-import-status .import-status-expanded .item-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
}

.service-import-wizard-container .import-service-details-table{
  border-top: 1px solid #B0B2BE;
}

.service-import-wizard-container .import-service-details-table td{
  text-align: left;
  padding-top: 18px;
}

.service-import-wizard-container .import-service-details-table td:last-child{
  text-align: right;
}

.service-import-wizard-container .import-service-details-table .empty-badge{
  height: 40px;
}

.service-import-wizard-container .import-service-details-table .status-badge{
  width: fit-content;
  margin-top: 8px;
}

.service-import-wizard-container .import-service-details-table .badge-container:first-child .status-badge{
  margin-top: 0;
}

.service-import-wizard-container .import-service-details-table .badge-container{
  width: 100%;
  height: 40px;
}

.import-item-name{
  height: 40px;
  max-width: 391px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.service-import-wizard-container .service-title{
  font-size: 11px;
}
