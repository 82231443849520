.generated-token-section{
  width: 702.37px;
  height: 220px;
  background: rgba(163, 200, 255, 0.3);
  border-radius: 10px;
  padding-top: 28px;
  padding-left: 31px;
  color: #000000;
  margin-top: 16px;
}

.generated-token-section .token-field{
  margin-top: 16px;
  margin-bottom: 16px;
}

.token-create-modal .modal-content{
  width: 748px;
}


.token-create-modal .modal-body{
  padding: 24px;
}

.copy-token-title{
  color: #D02828;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.expiration-time{
  margin-top:16px;
  height: 36px;
}

.no-expiration-label{
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  float: left;
  margin-left: 8px;
  line-height: 48px;
}

.expiration-date-picker{
  float: left;
  height: 48px;
}

.no-expiration-switch{
  float: left;
  margin-left: 24px;
  margin-top: 8px;
}

.no-expiration-indication{
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  border-radius: 5px;
  height: 48px;
  align-items: stretch;
  /* padding: 8px 24px; */
  width: 176px;
  background: #E5EDFF;
  float: left;
  color: #000000;
  text-align: center;
  padding-top: 8px;
}
