.edit-compute .edit-compute-header {
  height: 90px;
  margin-top: 10px;
  padding: 20px 0;
}

.edit-compute .fields-form {
  float: left;
  margin-right: 49px;
  height: 756px;
}

.edit-compute .code-container{
  float: right;
  height: 756px;
}

.edit-compute .code{
  border: 2px solid #B0B2BE;
  border-radius: 5px;
}

.code-field.full-screen {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 847px;
}

.edit-compute .code-field:first-child{
  margin-bottom: 40px;
}

.edit-compute .code-field.full-screen{
  margin-top: 0;
}

.edit-compute .edit-title {
  margin-bottom: 20px;
  ont-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  float: left;
}

.edit-compute .close-icon {
  float: right;
  margin-top: 4px;
  margin-right: 0px;
  cursor: pointer;
}

.edit-compute-form{
  height:823px;
}

.code-title{
  width: 100%;
  height: 20px;
  margin-bottom: 7.5px;
}

.code-title .form-label{
  float: left;
}

.code-title .code-fullscreen{
  float: right;
  cursor:pointer;
}

.edit-compute .edit-footer{
  border-top: 1px solid rgba(163, 200, 255, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
}

.edit-compute .edit-footer .ibutton:last-child{
  margin-right: 8px
}

.edit-compute .full-screen-container{
  position: relative;
  left: 0;
  float: left;
  width: 100%;
}

.trigger-checkbox{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height: 64px;
  float: left;
  width: 188px;
  margin-right: 16px;
  padding-left: 12px;
}

.trigger-checkbox .icheckbox{
  margin-top: 8px;
}

.trigger-checkbox .trigger-label {
  float: left;
  margin-top: 18px;
  margin-left: 22px;
}

.edit-compute .form-text-field{
  margin-bottom: 19px;
}

.edit-compute .triggers{
  height: 108px;
}

.edit-compute .form-error-message{
  width: 570px;
}

.edit-compute .add-route {
  margin-top: 10px;
}

.edit-compute .add-route-btn{
  margin-top: 9px;
  margin-right: 10px;
}

.computes .single-route {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}

.computes .paginated-table-container{
  position: relative;
}

.computes .paginated-table-container .table-loading{
  position: absolute;
  width: 100%;
  top: 90px;
}

.add-route-field{
  float: left;
  margin-top: 10px;
}
