.origin-set-member-add{
  display: flex;
  gap: 4px;
}

.origin-badge {
  background: #EDF0FF;
  border-radius: 4px;
  float: left;
  margin-right: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  padding: 8px;

  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #101010;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.origin-sets-edit .modal-dialog{
  width: 550px !important;
}

.lb-custom-rules-edit .modal-dialog{
  width: 1072px !important;
  max-width: 1072px !important;
}

.add-origin-member-btn{
  margin-top: 28px;
}

.origin-badge img{
  margin-right: 8px;
}

.origin-member-weights{
  display: flex;
  width: 506px;
  padding: 20px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 5px;
  border: 2px solid #B0B2BE;
}

.weights-title-row{
  display: flex;
  flex-direction: row;
  gap: 68px;
}

.weights-title-row .weights-title {
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

.split-equally {
  color: var(--blues-b-300, #0065FF);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  margin-top: 10px;
  cursor: pointer;
}

.wights-table{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.origin-weight-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.origin-weight-row.title div{
  color: var(--black-white-bw-500, #01072C);
  /* Subtitle/S2 */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.origin-weight-row div {
  color: var(--neutral-900, #111827);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.origin-weight-row.title div:last-child{
  width: 74px;
}

.member-badges{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-custom-rules-btn {
  width: 144px;
}

.add-custom-rules-btn button{
  height: 58px;
  line-height: 22px;
}
.origin-set-row-val {
  margin-bottom: 6px;
}
.origin-set-row-val img{
  margin-right: 8px;
}

.origin-member-row{
  margin-left: 12px;
  margin-top: 10px;
  min-width: 200px;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.origin-set-row-val .expand-icon{
  cursor: pointer;
}

.lb-custom-rules-form{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 500px;
  overflow: auto;
}

.lb-custom-rules-form div{
  width: 100%;
}

.lb-custom-rules-form div.ibutton.delete-condition {
  width: 48px;
}

.lb-custom-rules-form .add-lb-custom-rule-row{
  display: flex;
  justify-content: center;
}

.lb-custom-rules-form .add-lb-custom-rule-btn{
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 5px;
  border: 3px solid var(--outline-blue, #E5EDFF);
  color: var(--black-white-bw-500, #01072C);
  /* Special/Button */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0;
  text-transform: uppercase;
  width: 260px;
  height: 52px;
  cursor: pointer;
}

.lb-custom-rule-row {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid #B0B2BE;
  background: #FFF;
  padding-left: 21px;
  padding-top: 20px;
  padding-right: 30px;
  min-height: 100px;

}

.lb-custom-rule-row .lb-custom-rule-summary{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lb-custom-rule-row .edit-icon{
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-top: 8px;
}

.lb-custom-rule-row .info-cell .name{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 24.366px;
  font-style: normal;
  font-weight: 700;
  line-height: 34.809px; /* 142.857% */
}

.lb-custom-rule-row .info-cell .desc{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.lb-custom-rule-row .custom-rule-priority{
  display: flex;
  width: 42px;
  height: 60px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid #B0B2BE;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

.editing-custom-rule{
  margin-top: 32px;
}

.lb-custom-rules-form .condition-value, .lb-custom-rules-form .condition-value .input-group{
  width: 360px !important;
}


.lb-custom-rules-form .condition-value.cookie-value, .lb-custom-rules-form .condition-value.cookie-value .input-group{
  width: 192px !important;
}

.lb-custom-rule-row .toggle-content .down-arrow{
  width: fit-content;
}

.lb-custom-rule-row .add-condition-buttons .ibutton{
  width: fit-content;
}

.lb-custom-rule-row .editing-custom-rule .condition-line{
  min-height: 92px!important;
}

.lb-custom-rule-row h3{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

.lb-custom-rule-action-row {
  display: flex;
}

.lb-custom-rule-row .apply-changed-button{
  margin-top: 78px;
  margin-left: auto;
  width: 176px;
}

.lb-custom-rule-row .json-field{
  width: 200px;
}
