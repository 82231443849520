.traffic-policies .default-policy-row{
  background:  #E5EDFF;
}

.traffic-policy-wizard-container {
  width: 1152px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: calc(100vh - 112px);
}

.traffic-policy-wizard-container .final-step  .accordion-container h3{
  width: 100%;
}

.traffic-policy-wizard-container.last-step {
  margin-top: 0;
  text-align: left;
}

.traffic-policy-wizard-container .provider-wizard-step {
  height: 516px;
  width: fit-content;
  margin: auto;
}

.traffic-policy-wizard-container .wizard-buttons{
  height: 64px;
  text-align: center;
  width: fit-content;
  margin: auto;
}

.traffic-policy-wizard-container .wizard-buttons .ibutton:last-child{
  margin-left: 32px;
}

.provider-checkbox .provider-title{
  margin-left: 8px;
  margin-top: 17px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01072C;
}

.provider-checkbox {
  width: 226px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height: 64px;
  display: inline-block;
  margin-right: 25px;
  padding-left: 16px;
  margin-bottom: 48px;
}

.provider-checkbox img{
  margin-top: 14px;
}

.provider-checkbox .icheckbox{
  margin-right: 8px;
  margin-top: 12px;
}

.provider-checkbox .icheckbox .on{
  width: 36px !important;
  height: 36px !important;
  background: #0029FF url("/assets/icons/checkbox_checkmark.svg") no-repeat 8px 10px;
}

.provider-wizard-step .icheckbox .single-checkbox{
  padding: 0;
  width: 36px;
  height: 36px;
}

.provider-checkbox .icheckbox .off{
  width: 36px !important;
  height: 36px !important;
}

.traffic-policy-wizard-container  .provider-wizard-step .provider-checkboxes {
  width: 792px;
  margin: auto;
  height: 239px;
}

.traffic-policy-wizard-container  .switching-radios {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 93px;
}

.traffic-policy-wizard-container .final-step  .switching-radios {
  margin-left: 0;
  margin-bottom: 36px;
}

.traffic-policy-wizard-container .type-radio{
  width: 352px;
  border: 2px solid #B0B2BE;
  border-radius: 5px;
  height: 64px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 56px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 12px;
}

.traffic-policy-wizard-container  .type-radio input{
  margin-right: 15px;
  transform: scale(2);
}

.traffic-policy-wizard-container .accordion-button {
  height: 64px;
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #01072C;
  background: #E6F0FF !important;
}

.traffic-policy-wizard-container .accordion-button img{
  margin-right: 26px;
}

.traffic-policy-wizard-container .accordion-button::after {
  background: none !important;
}

.wizard-story{
  float: left;
}

.traffic-policy-wizard-container .final-step .accordion{
  width: 790px;
}

.wizard-story {
  width: 360px;
  height: calc(100vh - 112px);
  border-right: 1px solid #000000;
}

.wizard-story .story-title {
  font-family: 'Rajdhani', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 5px;
}

.wizard-story .story-section{
  padding: 16px 24px 32px;
  border-bottom: 1px solid #000000;
}

.final-step .accordion-container{
  border-right: 1px solid #000000;
  height: calc(100vh - 112px);
  padding-top: 22px;
}

.wizard-story .story-desc{
  margin-top: 5px;
}

.wizard-story .story-item{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  display: inline-block;
  margin-right: 5px;
}

.wizard-story .apply-policy{
  position: absolute;
  bottom: 0;
}

.wizard-story .apply-policy button{
  border-radius: 0;
}


.final-step .geo-button{
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.final-step .close-icon {
  float: right;
  margin-top: 4px;
  margin-right: 26px;
  cursor: pointer;
}

.wizard-story .provider-weight{
  margin-bottom: 5px;
}

.final-step .monitors {
  float: left;
}

.final-step .add-monitor{
  float: left;
  margin-left: 5px;
}

.final-step .monitor-container {
  height: 40px;
  margin-bottom: 36px;
}

.final-step .step-filled {
  position: absolute;
  right: 0;
}

.final-step .accordion .provider-weight{
  height: 98px;
  border-bottom: 1px solid #96C0FF;
  line-height: 96px;
  margin-top: 20px;
}

.final-step  .provider-icon{
  float: left;
  margin-right: 8px;
}

.final-step .provider-name{
  float: left;
  margin-right: 118px;
  width: 84px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.final-step  .provider-weight-input{
  float: left;
}

.no-default-message {
  border-radius: 5px;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background-color: rgba(245, 158, 11, 0.2);;
  background-repeat: no-repeat;
  background-position: 24px 20px;
  background-size: 20px 20px;
  background-image: url('/assets/icons/exclamation.svg');
  padding-left: 52px;
  margin-bottom: 10px;
  color: #782C00;
}

.wizard-story .form-error-message{
  padding-top: 12px;
  height: auto;
  position: absolute;
  bottom: 48px;
  width: 360px;
  line-height: 36px;
  padding-bottom: 16px;
}

.provider-distribution{
  margin-top: 3px;
}

.provider-distribution img{
  margin-right: 10px;
}

.traffic-policy-wizard-container h1{
  margin-bottom: 80px;
}

.traffic-policy-wizard-container h4{
  margin-bottom: 48px;
}

.provider-hint{
  position: relative;
  bottom: -134px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  opacity: 0.3;

}

.final-step .failover-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01072C;
  margin-bottom: 12px;
}

.final-step .accordion .provider-weight:last-child{
  border-bottom: none;
}

.penalty-label{
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 16px;
  float: left;
}

.enable-performance-penalty {
  height: 50px;
}

.enable-performance-penalty .icheckbox .off{
  width: 24px !important;
  height: 24px !important;
}

.enable-performance-penalty .icheckbox .on{
  width: 24px !important;
  height: 24px !important;
  background: #0029FF url("/assets/icons/checkbox_checkmark_24.svg") no-repeat 5px 7px;
}

.priority-provider {
  display: flex;
  flex-direction: row;
  gap: 13px;
  background: white;
  margin-bottom: 12px;
}

.priority-indicator {
  display: flex;
  height: 63px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgba(0, 41, 255, 0.20);
  width: 41px;
}

.provider-box{
  display: flex;
  gap: 24px;
  border-radius: 5px;
  border: 2px solid var(--black-white-bw-100, #B0B2BE);
  min-width: 243px;
  height: 64px;
  padding: 8px 16px;
  align-items: center;
}

.priority-provider-name{
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #01072C;
  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.priority-hint{
  color: #01072C;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 700px;
  margin-bottom: 12px;
}

.priority-separator{
  background: black;
  height: 1px;
  width: 210.184px;
  margin-bottom: 24px;
  margin-top: 12px;
  margin-left: 64px;
}

.traffic-policies .table-expanded, .traffic-policies .table-collapsed{
  margin-top: 18px;
  margin-left: 36px;
  float: right;
}

.expanded-traffic-policy{
  display: flex;
  flex-direction: row;
  gap:48px;
}

.traffic-policies .data-table .expanded-row td{
  border-bottom: 3px solid var(--Blues-B50, #E6F0FF);
  background: #F4F7FF;
}
