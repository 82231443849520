.traffic-graph-container{
  display: flex;
  padding: 17px 39px 25px 25px;
  border-radius: 5px;
  border: 3px solid #E5EDFF;
  background: #FFF;
  width: 100%;
  height: 100%;
}

.traffic-graph-container.empty {
  min-height: 414px;
}

.traffic-graph-container.has-bottom {
  min-height: 414px;
}

.big-graph .traffic-chart-container {
  height: 280px;
}

.traffic-graph-container.empty .traffic-chart-container{
  padding-left: 51px;
  margin-bottom: 28px;
}

.traffic-graph-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 100%;
}

.traffic-graph-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.traffic-chart-container {
  width: 100%;
  height: 202px;
}

.bottom-legend-container {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 33px;
}

.traffic-chart-title-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.chart-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
}

.chart-rate{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.424px; /* 71.401% */
}

.total-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5.731px;
}

.total-title {
  display: flex;
  width: 87.4px;
  height: 11.462px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: right;
  font-family: Rubik, sans-serif;
  font-size: 15.202px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px; /* 0% */
  letter-spacing: 0.608px;
  opacity: 0.5;
}

.total-value {
  display: flex;
  height: 18.806px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: right;
  font-family: Rubik, sans-serif;
  font-size: 26.514px;
  font-style: normal;
  font-weight: 700;
  line-height: 14.73px; /* 55.556% */
}

.top-controls-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.comparison-buttons-container{
  display: flex;
  width: 1104px;
  height: 323px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.comparison-buttons{
  display: flex;
  align-items: flex-start;
  gap: 35px;
}

.filters-container{
  display: flex;
  padding: 24px 0;
  flex-direction: column;
  align-items: flex-start;
  border-top: 2px solid #E6E6E6;
  border-bottom: 2px solid #E6E6E6;
}

.filters{
  display: flex;
  width: 1103px;
  justify-content: space-between;
  align-items: flex-start;
}

.geo-filter{
  display: flex;
  width: 529px;
  height: 84px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.additional-filters{
  display: flex;
  align-items: flex-start;
  gap: 37px;
}

.hits-bytes-filter{
  display: flex;
  height: 84px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.time-range-filter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.filter-label{
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;

  /* Subtitle/S2 */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.time-zone-hint{
  display: flex;
  height: 8px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0; /* 0% */
  letter-spacing: 0.48px;
  opacity: 0.5;
}

.hits-bytes{
  display: flex;
  height: 56px;
  align-items: flex-start;
  flex-shrink: 0;
}

.hits-btn {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 5px 0 0 5px;
  border: 2px solid #E5EDFF;
  cursor: pointer;
  color: #00164F;

  /* Special/Button */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px; /* 0% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.hits-btn.selected {
  background: #E5EDFF;
}

.bytes-btn {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 0 5px 5px 0;
  border: 2px solid #E5EDFF;
  cursor: pointer;
  color: #00164F;

  /* Special/Button */
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 0px; /* 0% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.bytes-btn.selected {
  background: #E5EDFF;
}

.view-mode-button{
  width: 336px;
  height: 198px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 11px 20px 0 rgba(177, 193, 216, 0.80);
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.view-mode-button.selected {
  border-radius: 10px;
  border: 3px solid #0267FF;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 7px 7.1px 0px rgba(177, 193, 216, 0.80);
}

.view-mode-button-top {
  display: flex;
  width: 304px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-left: 22px;
  margin-top: 16px;
  z-index: 506;
  position: relative;
}

.view-mode-button-header{
  display: flex;
  width: 304px;
  height: 36px;
  align-items: flex-start;
}

.view-mode-button-granularity{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.424px; /* 71.401% */
}

.view-mode-title-text-container {
  display: flex;
  width: 192px;
  height: 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.view-mode-button-title{
  display: flex;
  height: 17px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 142.857% */
  text-transform: capitalize;
}

.view-mode-button-tooltip-button{
  position: relative;
}

.view-mode-button-tooltip-button img{
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  opacity: 0.3;

}

.view-mode-button .grid-image {
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 10px;
  z-index: 500;
  left: 51px;
  overflow: hidden;
}

.grid-mask {
  position: absolute;
  bottom: 0;
  z-index: 501;
  width: 200px;
  overflow: hidden;
}

.view-container.analytics{
  width: 1104px;
  padding-bottom: 40px;
}

.graphs-grid {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-top: 24px;
}
.graphs-grid-line{
  display: flex;
  width: 1102px;
  align-items: flex-start;
  gap: 24px;
}

.account-analytics .graphs-grid-line{
  display: flex;
  width: 1102px;
  align-items: flex-start;
  gap: 24px;
}

.graphs-grid-cell{
  display: flex;
  width: 540px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.view-mode-button .chart-wrapper{
  padding-left: 22px;
  padding-top: 22px;
}

.legend-items {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
}

.legend-item {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.legend-item.disabled{
  opacity: 0.3;
}

.legend-color{
  width: 3.488px;
  height: 100%;
  border-radius: 2px;
}

.legend-item-text-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.legend-item-title{
  display: flex;
  height: 6.541px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0; /* 0% */
  letter-spacing: 0.48px;
}

.legend-item-value{
  display: flex;
  height: 14.845px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 20.929px;
  font-style: normal;
  font-weight: 700;
  line-height: 11.627px; /* 55.556% */
}

.legend-item-percentage {
  display: flex;
  padding: 4.6px;
  justify-content: center;
  align-items: center;
  gap: 4.651px;
  border-radius: 2.907px;
  background: #FF3D3D;
  color: #FFF;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.627px; /* 96.892% */
}

.view-mode-button.selected,.view-mode-button.selected:hover {
  border-radius: 10px;
  border: 3px solid #0267FF;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 7px 7.1px 0px rgba(177, 193, 216, 0.80);
}

.view-mode-button:hover{
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 7px 7.1px 0px rgba(177, 193, 216, 0.80);
}

.total-and-legend{
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.tooltip-toast.view-mode-tooltip{

}

.bottom-legend-container:not(.many) .legend-items {
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.bottom-legend-container.many .legend-items {
  width: 100%;
  height: 100%;
}

.bottom-legend-container.many .legend-items.space {
  justify-content: space-between;
}

.chartjs-tooltip{
  border-radius: 8px !important;
}

.chartjs-tooltip-body tr td span {
  height: 11px !important;
  width: 11px !important;
}

.traffic-graph-container .chartjs-tooltip-body-item {
  color: var(--Gray-Darker, #111827);
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.traffic-chart-container .chartjs-tooltip-body tr td span{
  margin-bottom: -2px;
}

.traffic-chart-container .chartjs-tooltip-header{
  display: flex;
  justify-content: flex-start;
}

.traffic-chart-container .chartjs-tooltip-header-item tr th{
  color: var(--Gray-Darker, #111827);
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.view-mode-button .tooltip-toast .tooltip-body{
  padding: 0;
}

.view-mode-button .tooltip-toast{
  display: flex;
  width: 289px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.traffic-chart-container.big .no-data-container {
  width: 846px;
  height: 320px;
}

.no-data-container{
  width: 418px;
  height: 202px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.no-data-text-container{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.big .no-data-text-container{
  margin-right: -50px;
}

.no-data-text{
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.no-data-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

.traffic-chart-container img {
  position: absolute;
}

.view-mode-button .chartjs-tooltip{
  display: none !important;
}

.missing-data-items{
  display: inline-flex;
  align-items: center;
  gap: 48px;
  width: 1025px;
}

.missing-data-icon{
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #FFFBDB;
}

.missing-data-icon img{
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.missing-data-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.missing-data-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

.missing-data-body{
  color: #000;

  /* Subtitle/S1 */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.missing-data-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 24px;
}

.disabled-stats-icon{
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #E5EDFF;
}

.disabled-stats-icon img{
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.tops-container{
  display: flex;
  width: 1152px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-bottom: 24px;
}

.devices-and-browsers{
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 48px;
  flex-wrap: wrap;
}

.devices{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-radius: 5px;
  border: 3px solid #E5EDFF;
  background: #FFF;
  width: 553px;
  flex: 1 0 0;
  gap: 25px;
  height: 493px;
}

.browsers{
  border-radius: 5px;
  border: 3px solid #E5EDFF;
  background: #FFF;
  display: flex;
  height: 493px;
  width: 553px;
  padding-top: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  flex-shrink: 0;
}

.devices-title-row{
  display: flex;
  width: 467px;
  justify-content: space-between;
  align-items: center;
  margin-left: 27px;
}

.devices-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 71.429% */
}

.browsers-title-row{
  display: flex;
  width: 464px;
  padding: 0;
  align-items: center;
  gap: 765px;
}

.browsers-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-left: 32px;
}

.devices-total{
  display: flex;
  width: 99px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #959595;
  text-align: right;
  font-family: Rajdhani, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 12.22px; /* 61.102% */
}

.devices-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.device{
  display: flex;
  height: 272px;
  flex-direction: column;
  align-items: center;
}

.device:not(:last-child) {
  border-right: 3px solid #E5EDFF;
}

.device-icon-container{
  display: flex;
  width: 173px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.device-icon-mobile{
  width: 48.621px;
  height: 94px;
  flex-shrink: 0;
}

.device-icon-desktop{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  fill: var(--Gradiernt-Color, linear-gradient(270deg, #0052FF 0%, #92B5FF 103.6%), #001C58);
  stroke-width: 2px;
  stroke: var(--Gradiernt-Color, #001C58);
}

.device-icon-unknown{
  width: 69.75px;
  height: 69.6px;
  flex-shrink: 0;
  fill: var(--Gradiernt-Color, linear-gradient(270deg, #0052FF 0%, #92B5FF 103.6%), #001C58);
}

.device-kpi-container{
  display: flex;
  height: 126px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
}

.device-kpi{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.device-kpi-text{
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  color: #001C58;
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 14.73px; /* 36.825% */
}

.device-kpi-label{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.424px; /* 71.401% */
}

.device-type{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.424px; /* 71.401% */
  display: flex;
  width: 174px;
  height: 45px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.device-percentage{
  display: flex;
  align-items: center;
  gap: 8px;
}

.device-pie{
  width: 31px;
  height: 31px;
}

.device-percentage-text{
  color: #001C58;
  font-family: Rubik, sans-serif;
  font-size: 22.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.75px; /* 150% */
}

.top-browsers-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

.top-3-browsers-container{
  margin-top: 16px;
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.top-3-browsers-container.big{
  justify-content: center;
}

.browsers-table-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 537px;
}



.top-browser{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.big .top-browser:not(:last-child){

  border-right: 3px solid #e5edff;
}

.top-browser-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
}

.top-browser-text{
  display: flex;
  align-items: center;
  gap: 8px;
}

.big .top-browser-text{
  flex-direction: column;
}

.big .top-browser{
  gap: 0;
  display: flex;
  height: 230px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 173px;
}

.big .top-browser-icon{
  margin-bottom: 24px;
}

.top-browser-title{
  display: flex;
  height: 11px;
  flex-direction: column;
  justify-content: center;
  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.top-browser-icon .big-browser-icon{
  display: flex;
  width: 74px;
  height: 78px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-browser-circle{
  position: absolute;
  left: -5px;
  top: 24px;
  fill: linear-gradient(180deg, #0267FF 0%, #013E99 100%);
}

.big .top-browser-circle{
  top: 0;
}

.top-browser-index{
  position: absolute;
  left: -1px;
  top: 27px;
  color: #FFF;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px; /* 150% */
}

.big .top-browser-index{
  top: 2px;
}

.top-browser-kpi{
  color: #04266B;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.big .top-browser-kpi{
  font-size: 40px;
  line-height: 40px;
}

.kpi-separator{
  color: #000;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  opacity: 0.1;
}

.browser-percentage{
  display: flex;
  align-items: center;
  gap: 8px;
}


.browser-pie{
  width: 18px;
  height: 18px;
}

.big .kpi-separator {
  display: none;
}

.big .browser-pie{
  width: 31px;
  height: 31px;
}

.browser-percentage-text{
  color: #04266B;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.big .browser-percentage-text{
  font-size: 22.5px;
}

.browser-row{
  display: flex;
  padding: 3px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: #FFF;
  width: 100%;
  overflow-x: hidden;
}

.browser-row-container{
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #FFF;
}

.browser-title-cell{
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.browser-row-index{
  color: #434343;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.browser-row-icon{
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.browser-row-icon img{
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.browser-values-cell{
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.browser-values-cell-kpi-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
}

.browser-values-cell-kpi{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #04266B;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.browser-row-container img.row-border{
  stroke-width: 1px;
  stroke: #445164;
}

.browser-name{
  color: rgba(0, 0, 0, 0.50);
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 24px;
  max-width: 941px;
  position: relative;
}

.browser-name img{
  display: none;
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
}

.browser-name:hover img{
  display: block;
}

.half .browser-name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 24px;
  max-width: 338px;
}

.browser-percentage-container{
  display: flex;
  align-items: center;
  gap: 6px;
}

.percentage-text-container{
  display: flex;
  align-items: center;
  gap: 3px;
}

.percentage-text{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #04266B;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.browsers-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
  height: 100%;
  justify-content: center;
}

.top-stats .ipagination .pagination{
  margin: 0;
}

.pagination-container{
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: auto;
}

.top-domains{
  width: 1152px;
  height: fit-content;
  border-radius: 5px;
  border: 3px solid #E5EDFF;
  background: #FFF;
  padding: 29px 25px 4px 9px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.top-domains-content{
  display: flex;
  gap: 55px;
  height: 100%;
}

.top-domains-header{
  display: flex;
  width: 1069px;
  padding: 0 23px;
  align-items: center;
  gap: 8px;
  position: relative;
}


.top-domains-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 71.429% */
}

.domains-table-container{
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}



.half .domains-table-container{
  width: 537px;
}

.domains-table-container .browser-row {
  margin-bottom: 12px;
}

.domains-table-header{
  display: flex;
  padding-bottom: 5px;
  align-items: flex-end;
  gap: 24px;
  justify-content: flex-end;
  padding-right: 24px;
}

.domains-table-header-cell{
  display: flex;
  width: 60px;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.50);
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 130.769% */
}

.domains-table-header-cell.vol{
  width: 22px;
}

.top-stats .no-data-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.top-stats .no-data-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

.top-stats .no-data-hint{
  color: var(--black-white-bw-500, #01072C);

  /* Body/Large */
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.devices-container .no-data-container{
  margin-top: -90px;
}

.browsers-content .no-data-container{

}

.browsers-content .device-kpi-label{
  display: none;
}

.browsers-content .big .device-kpi-label{
  display: block;
  margin-bottom: 12px;
}

.browsers-content .big .top-browser-title{
  margin-bottom: 13px;
}

.top-domains .browser-percentage-container{
  width: 60px;
}

.date-range-picker.top-stats-range-picker .date-range-picker-toast{
  margin-right: 0;
}

.date-range-picker.top-stats-range-picker .date-range-picker-toast .calendar-pointer{
  right: 500px;
}

.date-range-picker.geo-analytics-range-picker .date-range-picker-toast{
  margin-right: 0;
  top: 210px;
}

.date-range-picker.geo-analytics-range-picker .date-range-picker-toast .calendar-pointer{
  right: 500px;
}

.force_visible{
  display: block !important;
}

.top-stats .tooltip-toast{
  position: absolute;
  top: 12px;
  width: fit-content;
}

.top-stats .tooltip-toast .tooltip-body{
  padding: 8px;
}

.account-analytics{
  padding-top: 86px;
}

.account-analytics-title-container{
  display: flex;
  padding: 24px 0;
  align-items: center;
}

.account-analytics-title{
  color: #215EDF;

  font-family: Rajdhani, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}

.account-graphs-container{
  border-radius: 10px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  box-shadow: 10px 19px 34px 0 rgba(177, 193, 216, 0.80);
  padding: 32px;
}


.account-analytics .filters-container{
  align-items: flex-end;

  width: 100%;
}
.account-analytics .filters-container .filters{
  width: 100%;
}

.account-analytics .filters-container .additional-filters {
  width: fit-content;
}

.account-analytics .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.account-analytics .services{
  margin-bottom: 34px;
}

.account-analytics .services .list-headers{
  padding-left: 0;
}

.flex-start{
  align-self: flex-start;
}

.flex-end {
  align-self: flex-end;
}

.margin-left-auto{
  margin-left: auto;
}

.account-graphs-container .big-graph{
  margin-top: 54px;
}

.account-graphs-container {
  margin-bottom: 24px;
}

.account-graphs-container .account-analytics-title-container{
  margin-bottom: 24px;
}

.account-analytics .multiselect-container .searchWrapper {
  height: 56px;
  width: 519px;
  padding-top: 8px;
  padding-left: 16px;
}
.account-analytics .multiSelectContainer input{
  margin-top: 6px;
}

.clickable{
  cursor: pointer;
}

.account-analytics .graphs-grid-cell{
  width: 664px;
}

.account-analytics .graphs-grid{
  margin-top: 24px;
}

.account-analytics .big-graph .traffic-chart-container{
  height: 352px;
}

.view-container.account-analytics{
  width: 1416px;
}

.account-analytics-all-services{
  color: #0029FF;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.account-analytics .highlightOption{
  background: white;
}

.account-analytics .date-range-picker-toast{
  top: 800px;
}

.rsm-svg{
  background: #d3dce9;
  border-radius: 16px;
}


.geo-stats .filters-container{

  border: none;
}

.map-container{
  display: flex;
  width: 1104px;
  padding: 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  border-radius: 8px;
  border: 3px solid #E5EDFF;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(90deg, #01072C 0%, #182574 100%);
  position: relative;
}

.map-header-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 71.429% */
}

.zoom-controls{
  width: 37.646px;
  height: 75.291px;
  flex-shrink: 0;
  border-radius: 6.547px;
  background: #FFF;
  position: absolute;
  right: 16px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px 9px;
}

.zoon-in, .zoom-out{
  width: 19.641px;
  height: 19.641px;
  flex-shrink: 0;
  cursor: pointer;
}

.zoom-controls img{
  cursor: pointer;
}

.map-wrapper{
  position: relative;
  width: 1051px;
  height: 544px;
}

.center-map {
  position: absolute;
  right: 16px;
  bottom: 108px;
  width: 37.646px;
  height: 37.646px;
  flex-shrink: 0;
  border-radius: 6.547px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px 9px;
  cursor: pointer;
}

.color-legend{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.map-color-item{
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.map-color-item-percentage{
  align-self: stretch;
  color: var(--black-white-bw-500, #01072C);
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.867px; /* 91.281% */
  min-height: 12px;
}

.map-color-item-color{
  height: 20px;
  align-self: stretch;
}

.map-color-item:first-child .map-color-item-color{
  border-radius: 5px 0 0 5px;
}

.map-color-item:last-child .map-color-item-color{
  border-radius: 0 5px 5px 0;
}

.top-geos-container{
  display: flex;
  width: 1104px;
  padding: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 3px solid #E5EDFF;
}

.top-geos-header{
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.top-geos-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 71.429% */
}

.geo-stats .tooltip-toast{
  position: absolute;
  top: 12px;
  width: fit-content;
}

.geo-stats .tooltip-toast .tooltip-body{
  padding: 8px;
}

.top-geos-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.top-geos-table-header{
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.top-geos-table-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.top-geos-table-header-cell{
  color: rgba(0, 0, 0, 0.50);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
  text-align: left;
}

.top-geos-table-header-cell.name {
  width: 400px;
}

.top-geos-table-header-cell.provider, .top-geos-table-header-cell.total {
  width: 100px;
}

.top-geos-table-header-cell.graph{
  align-self: stretch;
}

.top-geos-table-cell{
  text-align: left;
}

.top-geos-table-cell.name{
  width: 400px;
  color: rgba(0, 0, 0, 0.50);
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.top-geos-table-cell.name img{
  margin-right: 8px;
}

.top-geos-table-cell.provider, .top-geos-table-cell.total {
  width: 100px;
}

.top-geos-table-cell.provider .val, .top-geos-table-cell.total{
  color: #04266B;
  text-align: left;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.top-geos-table-cell.provider .percentage{
  display: flex;
  padding: 0 4px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #E6F0FF;
  color: var(--Neutral-900, #111827);
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: fit-content;

}

.top-geos-table-cell.graph{
  align-self: stretch;
}

.top-geo-row{
  display: flex;
  align-items: center;
  align-self: stretch;
  height: 64px;
  border-bottom: 1px solid var(--Blues-B75, #96C0FF);
}

.geo-stats .pagination-container{
  width: 100%;
  display: flex;
  align-items: center;
}

.view-container.geo-stats {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-bottom: 36px;
}

.top-geos-table-cell.graph {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex: 1;
  align-items: center;
}

.top-geos-table-cell.graph .graph-container{
  width: 100%;
  align-items: center;
  display: flex;
}

.top-geos-table-cell.graph .bar {
  display: flex;
  width: 190px;
  height: 32px;
  padding: 6px 7px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: linear-gradient(270deg, #0052FF 0%, #CDDDFF 103.6%), linear-gradient(270deg, #0052FF 0%, rgba(33, 94, 223, 0.32) 103.6%), #E6E6E6;
}

.graph-percent{
  color: #04266B;
  font-family: Rubik, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  align-items: center;
  height: 24px;
}

.map-wrapper.mouse-down svg{
  cursor: url("/assets/icons/drag-cursor.svg"), pointer;
}

.map-wrapper.zoom-in svg{
  cursor: url("/assets/icons/zoomout-cursor.svg"), pointer;
}

.map-wrapper.zoom-out svg{
  cursor: url("/assets/icons/zoomin-cursor.svg"), pointer;
}
.geo-tooltip {
  position: absolute;
}
.geo-tooltip .tooltip-body{
  padding: 16px;
}

.geo-tooltip-title{
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
}

.tooltip-provider-row{
  display: flex;
  align-items: center;
  gap: 20px;
}

.tooltip-row-title{
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.715px; /* 130.962% */

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  width: 80px
}

.tooltip-provider-name{
  display: flex;
  width: 70px;
  height: 8.203px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik, sans-serif;
  font-size: 10.477px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.715px; /* 150% */
}

.tooltip-provider-name img{
  margin-right: 5px;
  max-height: 16px;
}

.tooltip-provider-value{
  color: #04266B;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
}

.tooltip-provider-graph{
  flex: 1
}

.tooltip-provider-graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  color: var(--Blues-B300, #0065FF);
  text-align: right;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.tooltip-provider-graph-bar{
  height: 14px;
  border-radius: 5px;
  background: linear-gradient(270deg, #0052FF 0%, #CDDDFF 103.6%), linear-gradient(270deg, #0052FF 0%, rgba(33, 94, 223, 0.32) 103.6%), #E6E6E6;
}

.geo-tooltip-body {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.geo-tooltip-footer {
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.geo-tooltip-footer a{
  color: var(--Blues-B200, #2B7FFF);
  font-family: Rajdhani, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */
  text-decoration-line: underline;
}

.legend-colors{
  display: flex;
  flex-direction: column;
}

.color-title{
  width: 100px;
  text-align: center;
  color: var(--black-white-bw-500, #01072C);
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.641px; /* 151.086% */
  margin-top: 15px;
}

.unique-users {
  width: 100%;
}
